<form *ngIf="step===1" [formGroup]="idForm">
    <span *ngIf="isExtraAuthRequired && !isLoginWithMax && !loginPageMode && !isOpenBankingLogin" class="title main-title">לפני שנוכל לאפשר את ביצוע הפעולה נעשה זיהוי קצרצר</span>
    <span class="title max-title" [ngClass]="{'subtitle': isExtraAuthRequired}">{{titleText}}</span>
    <div class="form-group id-or-passport">
        <input formControlName="id" appNumberWithoutSymbol maxlength="9" type="tel" class="form-control" maxlength="9" id="id-passport" placeholder="מספר תעודת זהות או דרכון" (blur)="idBlured($event)" trim #id (focus)="putCaretInTheEnd($event)">
        <span class="error-wrapper" *ngIf="isIdEmpty()"><span class="error-msg">נא למלא עד 9 ספרות, כולל ספרת ביקורת ללא אותיות</span></span>
        <span class="error-wrapper"><span class="error-msg" *ngIf="isIdNotValidByPattern()">נא למלא עד 9 ספרות, כולל ספרת ביקורת ללא אותיות</span></span>
    </div>
    <div class="button-wrapper send-code-mobile">
        <button class="general-button send-me-code" id="send-code" (click)="onSendMeCodeClicked()">
            <span>{{ isMobile ? "שלחו לי קוד" : "שלחו לי קוד לנייד" }}</span>
        </button>
    </div>
    <div class="button-wrapper send-code-mail" *ngIf="isMailOtpEnabled">
        <button class="link" tabindex="3" (click)="sendMailOtp()">
            <span>שלח לי קוד למייל</span>
        </button>
    </div>
    <div class="button-wrapper kosher-wrapper">
        <span class="kosher-phone">לנייד "כשר" הקוד יגיע בשיחת טלפון</span>
    </div>
    <div class="button-wrapper kosher-wrapper">
        <span class="register">
            עדיין לא נרשמת לאזור האישי?
            <a [attr.href]="registrationUrl"
               title=""
               class="link skip-blur"
               id="sign-now"
               [attr.target]="isLoginWithMax ? '_blank' : '_self'">כאן נרשמים</a>
        </span>
    </div>
</form>
<form *ngIf="step===1.5" [formGroup]="emailForm" (ngSubmit)="onSendMeMailClicked()">
    <span class="title" [ngClass]="{'subtitle': isExtraAuthRequired}">מה כתובת המייל אליה נשלחים דפי פירוט החיובים?</span>
    <div class="form-group id-or-passport">
        <input formControlName="email" maxlength="55" type="email" class="form-control" placeholder="כתובת מייל" trim (focus)="putCaretInTheEnd($event)">
        <span class="error-wrapper" *ngIf="isEmailEmpty()"><span class="error-msg">נא למלא כתובת מייל</span></span>
        <span class="error-wrapper"><span class="error-msg" *ngIf="isEmailNotValidByPattern()">יש למלא מייל באופן הבא example@mail.com</span></span>
    </div>
    <div class="button-wrapper">
        <button type="submit" class="general-button send-me-code">
            <span>שלחו את הקוד</span>
        </button>
    </div>
</form>
<form *ngIf="step===2" [formGroup]="verifyForm">
    <span class="title">{{verifyCodeTitle}}</span>
    <div class="type-code">
        <div class="form-group">
            <input autocompleteOff formControlName="verifyCode" appNumberWithoutSymbol maxlength="5" type="tel" class="form-control" id="verify-code" placeholder="{{verifyCodePlaceholderText}}" trim #verifyCode (focus)="putCaretInTheEnd($event)">
            <span class="error-wrapper"><span class="error-msg" *ngIf="showIdErrorMessage()">נא למלא ספרות בלבד, 5 ספרות בדיוק</span></span>
        </div>
        <div class="last-digit">
            <div class="form-group">
                <div>
                    <input type="radio" value="card8Digits" name="verifyDigitsRadio" (click)="radioOptionClicked('card8Digits')" formControlName="verifyDigitsRadio" id="rdbCard8Digits">
                    <label for="rdbCard8Digits" class="verify-digits-radio">8 ספרות אחרונות של הכרטיס</label>
                </div>
                <div>
                    <input type="radio" value="accountNumber" name="verifyDigitsRadio" (click)="radioOptionClicked('accountNumber')" formControlName="verifyDigitsRadio" id="rdbAccountNumber">
                    <label for="rdbAccountNumber" class="verify-digits-radio bank-radio">חשבון בנק</label>
                </div>
            </div>
        </div>
        <div class="form-group">
            <input formControlName="verifyDigitsValue" autocomplete="off" appNumberWithoutSymbol [attr.maxlength]="getMaxLengthOfVerifyDigits()" type="tel" class="form-control card-number" id="card-num-bank" trim (focus)="putCaretInTheEnd($event)">
            <span class="error-wrapper">
                <span class="error-msg" *ngIf="showVerify8DigitsErrorMessage()">נא למלא ספרות בלבד, 8 ספרות בדיוק</span>
                <span class="error-msg" *ngIf="showVerifyAccountNumberErrorMessage()">מספר חשבון הבנק בו מחויב כרטיסך, עד 9 ספרות</span>
                <span class="error-msg" *ngIf="showVerifyAccountNumberZerosErrorMessage()">מספר חשבון הבנק אינו יכול להכיל רק אפסים</span>
            </span>
        </div>
    </div>
    <div class="confirm-terms form-group">
        <div>
            <input type="checkbox" formControlName="confirmTerms" id="confirmTerms" (click)="onConfirmTermsClicked()" required />
            <label class="label-terms" for="confirmTerms"> קראתי ואני מאשר/ת את <a [attr.href]="confirmTermsUrl" title="" class="link" target="_blank">תנאי השימוש</a></label>
        </div>
        <span class="error-wrapper">
            <span class="error-msg" *ngIf="isTermsCheckboxNotChecked()">נא לאשר את תנאי השימוש על מנת להמשיך בתהליך</span>
        </span>
    </div>
    <span *ngIf="showError" class="error-msg">
        {{errorMsg}}
    </span>
    <div class="button-wrapper">
        <button class="general-button send-me-code" id="sen-me-code" (click)="onLoginClicked()">
            <span>לכניסה לאזור האישי</span>
        </button>
        <span class="not-recive-code">לא קיבלתם את הקוד? <a (click)="onNotRecivedCodeClicked()" title="" href="javascript:void(0)" class="link" id="click-here">לחצו כאן</a></span>
    </div>
</form>
<div *ngIf="step===3" class="no-code">
    <span class="title">לא קיבלת את הקוד?</span>
    <p class="text">
        אפשר לנסות להקליד מחדש מספר תעודת זהות או
        <a (click)="goToUserLoginForm()" href="javascript:void(0)" title="" class="link">להכנס עם שם משתמש וסיסמה</a>
    </p>
    <a (click)="tryAgainOtpClicked()" href="javascript:void(0)" title="" class="general-button send-me-code">לכניסה חוזרת עם תעודת זהות</a>
</div>