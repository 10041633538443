import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { RouterModule } from '@angular/router';
// import { HttpModule } from '@angular/http';
import { appRoutes } from '../app.routes';
import { HTTP } from './http/http.service';
import { FlexLayoutModule } from '@angular/flex-layout';
import { UiAutofocusDirective } from './directives/ui-autofocus/ui-autofocus.directive';
import { SharedModule as SharedPrimeng } from 'primeng/api';
// import { DynamicFormsModule } from "./components/form-components/dynamic-form.module";
import { RestApiService } from './services/rest-api.service';
import { DialogComponent } from './components/dialog/dialog.component';
import { HeaderContentComponent } from './components/header-content/header-content.component';
import { UiFedexComponent } from './components/dialog/messages/ui-fedex/ui-fedex.component';
import { UiFedexCryptoComponent } from './components/dialog/messages/ui-fedex-crypto/ui-fedex-crypto.component';
import { UiOpenRequestComponent } from './components/dialog/messages/ui-open-request/ui-open-request.component';
import { UiExistCustomerComponent } from './components/dialog/messages/ui-exist-customer/ui-exist-customer.component';
import { UiSMSCodeComponent } from './components/dialog/messages/ui-smscode/ui-smscode.component';
import { UiNotEligibleComponent } from './components/dialog/messages/ui-not-eligible/ui-not-eligible.component';
import { UiStopJoinFormComponent } from './components/dialog/messages/ui-stop-join-form/ui-stop-join-form.component';
import { UiGeneralErrorComponent } from './components/dialog/messages/ui-general-error/ui-general-error.component';
import { UiSendOtpComponent } from './components/dialog/messages/ui-send-otp/ui-send-otp.component';
import { UiDreamCardJoiningComponent } from './components/dialog/messages/ui-dream-card-joining/ui-dream-card-joining.component';
import { UiFedexScheduleComponent } from './components/dialog/messages/ui-fedex-schedule/ui-fedex-schedule.component';
import { UiSimplePaySessionLostComponent } from './components/dialog/messages/ui-simplepay-session-lost/ui-simplepay-session-lost.component';
import { UiSimplePaySessionTimeoutComponent } from './components/dialog/messages/ui-simplepay-session-timeout/ui-simplepay-session-timeout.component'
// import { MobileMaskPipe } from './pipes/mobile-mask.pipe';
import { TypingAnimationDirective } from './directives/typing-animation/typing-animation.directive';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CompareService } from './services/compare.service';
import { PopupComponent } from './components/popup/popup.component';
import { CardItemComponent } from '../modules/cards/components/all-cards/components/card-item/card-item.component';
import { JoinService } from './services/join.service';
import { FormService } from './components/form-components/dynamic-form-services/form.service';
import { FormBuilderService } from './components/form-components/dynamic-form-services/form-builder.service';
import { FormControlService } from './components/form-components/dynamic-form-services/form-control.service';
import { SessionTimeoutComponent } from './components/dialog/messages/session-timeout/session-timeout.component';
// import { NoopAnimationsModule, BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UiStopJoinFormSuccessComponent } from './components/dialog/messages/ui-stop-join-form/ui-stop-join-form-success.component';
import { RemoveBlacklistCharactersOnPasteDirective } from './directives/remove-blacklist-characters-on-paste/remove-blacklist-characters-on-paste.directive';
import { UiExitTrackerComponent } from './components/dialog/messages/ui-exit-tracker/ui-exit-tracker.component';
import { UiStopUploadFilesComponent } from './components/dialog/messages/ui-stop-upload-files/ui-stop-upload-files.component';
import { TypingFormatDirective } from './directives/typing-format/typing-format.directive';
import { DisablePasteDirective } from './directives/disable-paste/disable-paste.directive';
import { UiFairCreditLawComponent } from './components/dialog/messages/ui-fair-credit-law/ui-fair-credit-law.component';
import { ContinueProcessSmsSentComponent } from './components/dialog/messages/sales-promotion/continue-process-sms-sent/continue-process-sms-sent.component';
import { CardholdersCoreModule } from './cardholders-core/cardholders-core.module';
import { HelperService } from './services/helper.service';
import { UiDigitalSignedComponent } from './components/dialog/messages/ui-digital-signed/ui-digital-signed.component';
import { UiProfileSendOtpComponent } from './components/dialog/messages/ui-profile-send-otp/ui-profile-send-otp.component';
import { UiPopulationRegistryErrorComponent } from './components/dialog/messages/ui-population-registry-error/ui-population-registry-error.component';
import { UiFairCreditProductsComponent } from './components/dialog/messages/ui-fair-credit-products/ui-fair-credit-products.component';
import { UiPartialSuccessNeedEligibilityMessageComponent } from './components/dialog/messages/ui-partial-success-need-eligibility-message/ui-partial-success-need-eligibility-message.component';
import { LottieModule } from './modules/lottie/lottie.module';
import { SpinnerModule } from './modules/spinner/spinner.module';
import { StepsFormModule } from './modules/steps-form/steps-form.module';
import { CustomerLoanPopupModalComponent } from './components/dialog/messages/ui-customer-loan-popup/ui-customer-loan-popup.component';
import { CustomerLoanCallMeBackModalComponent } from './components/dialog/messages/ui-customer-loan-call-me-back/ui-customer-loan-call-me-back.component';
import { MonthlyBillingPopupModalComponent } from './components/dialog/messages/ui-monthly-billing-popup/ui-monthly-billing-popup.component';
import { SpreadTransactionCreditDataPopupModalComponent } from './components/dialog/messages/ui-spread-transaction-credit-data-popup/ui-spread-transaction-credit-data-popup.component';
import { CreditDataContactUsModalComponent } from './components/dialog/messages/ui-credit-data-contact-us-popup/ui-credit-data-contact-us-popup.component';
import { UiTauUniqSuccessComponent } from './components/dialog/messages/ui-tau-uniq-success/ui-tau-uniq-success.component';
import { CookiesAlertComponent } from './components/cookies-alert/cookies-alert.component';
import { CookiesCheckerDirective } from './directives/cookies-checker/cookies-checker.directive';
import { UiRegisteredCustomerComponent } from './components/dialog/messages/ui-registered-customer/ui-registered-customer.component';
import { UiSMSCodeRegisteredComponent } from './components/dialog/messages/ui-smscode-registered/ui-smscode-registered.component';

import { StopBackDirective } from './directives/stop-back-button/stop-back-button.directive';
import { UiCardDeliveryComponent } from './components/dialog/messages/ui-card-delivery/ui-card-delivery.component';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CheckboxModule } from 'primeng/checkbox';
import { AccordionModule } from 'primeng/accordion';
import { UiCallCustomerServiceComponent } from './components/dialog/messages/ui-call-customer-service/ui-call-customer-service.component';
import { AnimatedCounterComponent } from './animated-counter/animated-counter.component';
import { UiAddressErrorComponent } from './components/dialog/messages/ui-address-error/ui-address-error.component';
import { UiPhoneNotFoundComponent } from './components/dialog/messages/ui-phone-not-found/ui-phone-not-found.component';
import { UiDidntGetCodeComponent } from './components/dialog/messages/ui-didnt-get-code/ui-didnt-get-code.component';
import { SignaturepadComponent } from './components/signaturepad/signaturepad.component';
import { SignaturePadModule } from 'angular2-signaturepad';
import { UiMaxAccountMsgComponent } from './components/dialog/messages/ui-max-account-msg/ui-max-account-msg.component';
import { BorderEveComponent } from './components/border-eve/border-eve.component';
import { ExpDateComponent } from './components/exp-date/exp-date.component';
import { Recaptcha_V2Component } from './components/recaptcha_V2/recaptcha_V2.component';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';

import { RecaptchaHelperService } from './services/recaptcha-helper.service';
import { ToastComponent } from './components/toast/toast.component';

@NgModule({
    imports: [
        CommonModule,
        // HttpModule,
        FlexLayoutModule,
        RouterModule,
        DialogModule,
        ButtonModule,
        InputTextModule,
        ReactiveFormsModule,
        FormsModule,
        AutoCompleteModule,
        CardholdersCoreModule,
        SharedPrimeng,
        LottieModule,
        SignaturePadModule,
        SpinnerModule,
        StepsFormModule,
        RecaptchaModule,
        RecaptchaFormsModule
        // NoopAnimationsModule,
        // BrowserAnimationsModule,
        // DynamicFormsModule,
        // LottieAnimationViewModule.forRoot()
    ],
    declarations: [
        UiAutofocusDirective,
        DialogComponent,
        UiFedexComponent,
        UiFedexCryptoComponent,
        HeaderContentComponent,
        UiOpenRequestComponent,
        UiExistCustomerComponent,
        UiSMSCodeComponent,
        UiSMSCodeRegisteredComponent,
        UiNotEligibleComponent,
        UiStopJoinFormComponent,
        UiGeneralErrorComponent,
        UiDreamCardJoiningComponent,
        UiSendOtpComponent,
        UiRegisteredCustomerComponent,
        UiFedexScheduleComponent,
        UiSimplePaySessionLostComponent,
        UiSimplePaySessionTimeoutComponent,
        TypingAnimationDirective,
        PopupComponent,
        CardItemComponent,
        SessionTimeoutComponent,
        UiStopJoinFormSuccessComponent,
        UiExitTrackerComponent,
        RemoveBlacklistCharactersOnPasteDirective,
        TypingFormatDirective,
        DisablePasteDirective,
        UiFairCreditLawComponent,
        UiFairCreditProductsComponent,
        UiStopUploadFilesComponent,
        ContinueProcessSmsSentComponent,
        UiDigitalSignedComponent,
        UiProfileSendOtpComponent,
        UiPopulationRegistryErrorComponent,
        UiPartialSuccessNeedEligibilityMessageComponent,
        CustomerLoanPopupModalComponent,
        CustomerLoanCallMeBackModalComponent,
        MonthlyBillingPopupModalComponent,
        CreditDataContactUsModalComponent,
        UiTauUniqSuccessComponent,
        CookiesAlertComponent,
        CookiesCheckerDirective,
        StopBackDirective,
        UiCardDeliveryComponent,
        UiCallCustomerServiceComponent,
        UiAddressErrorComponent,
        AnimatedCounterComponent,
        UiPhoneNotFoundComponent,
        UiDidntGetCodeComponent,
        SignaturepadComponent,
        SpreadTransactionCreditDataPopupModalComponent,
        UiMaxAccountMsgComponent,
        BorderEveComponent,
        ExpDateComponent,
        Recaptcha_V2Component,
        ToastComponent
    ],
    providers: [
        HTTP,
        CompareService,
        JoinService,
        FormService,
        FormBuilderService,
        FormControlService,
        { provide: LocationStrategy, useClass: PathLocationStrategy },
        HelperService,
        RecaptchaHelperService,

    ],
    exports: [
        HeaderContentComponent,
        FlexLayoutModule,
        UiAutofocusDirective,
        CheckboxModule,
        AccordionModule,
        SharedPrimeng,
        // DynamicFormsModule,
        DialogModule,
        DialogComponent,
        ButtonModule,
        UiStopJoinFormComponent,
        TypingAnimationDirective,
        PopupComponent,
        CardItemComponent,
        RemoveBlacklistCharactersOnPasteDirective,
        TypingFormatDirective,
        DisablePasteDirective,
        SpinnerModule,
        CookiesCheckerDirective,
        CookiesAlertComponent,
        StopBackDirective,
        AnimatedCounterComponent,
        StepsFormModule,
        SignaturepadComponent,
        BorderEveComponent,
        ExpDateComponent,
        Recaptcha_V2Component,
        ToastComponent
        // NoopAnimationsModule,
        // BrowserAnimationsModule,
    ],
    entryComponents: [CookiesAlertComponent]
})
export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [JoinService, FormService, RecaptchaHelperService]
        };
    }
}
