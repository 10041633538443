<section class="top-content-wrapper">
    <app-hello></app-hello>
    <app-notifications></app-notifications>
    <app-my-cards></app-my-cards>
    <app-my-mymax-cards></app-my-mymax-cards>
    <app-my-foreign-currency *ngIf="
            appConfigService.appConfigs.Flag_ISEnableForeignCurrencyPocketsInHomePage">
    </app-my-foreign-currency>
    <app-max-account *ngIf="
            appConfigService.appConfigs.FLAG_ISShowMaxAccountInDashboard &&
            maxAccountRes?.returnCode === 0 &&
            maxAccountRes?.result?.isMerchantExists
        "
                     [maxAccountRes]="maxAccountRes"></app-max-account>
    <app-my-bog></app-my-bog>
    <app-my-points></app-my-points>
    <app-policy></app-policy>
    <app-my-max-info></app-my-max-info>
</section>
<section class="benefits-section">
    <app-benefits></app-benefits>
</section>
<app-newsletter></app-newsletter>
<app-registered-products></app-registered-products>

