import { NgModule, ModuleWithProviders } from '@angular/core';
import { MobileMaskPipe } from './pipes/mobile-mask.pipe';
import { TelLinkPipe } from './pipes/tel-link.pipe';
import { SanitizeHtmlPipe } from './pipes/sanitize-html.pipe';
import { SanitizeResourceUrlPipe } from './pipes/sanitize-resource-url.pipe';
import { SortPipe } from './pipes/sort/sort.pipe';
import { FilterPipe } from './pipes/filter/filter.pipe';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { PopupsService } from './services/popups.service';
import { SpinnerService } from './services/spinner.service';
import { HttpService } from './services/http-service.service';
import { UiHelperService } from './services/ui-helper.service';
import { ModuleTransferService } from './services/module-transfer.service';
import { AuthService } from './services/auth.service';
import { ContentsService } from './services/contents.service';
import { AppLinkDirective } from './directives/app-link.directive';
import { AppLoaderDirective } from './directives/app-loader.directive';
import { AppLinkPreLoadDirective } from './directives/app-link-preload';
import { GeneralTextsService } from './services/generaltexts.service';
import { AppNumberDirective } from './directives/app-number.directive';
import { GtmService } from './services/gtm.service';
import { GAExtraDataService } from './services/ga-extra-data.service';
import { AppGtmDirective } from './directives/app-gtm.directive';
import { AppLoadStyleDirective } from './directives/app-load-style';
import { ComadminDisabledDirective } from './directives/comAdminDisabled.directive';
import { BackgroundDirective } from './directives/app-background.directive';
import { SeoHeadComponent } from './components/seo-head/seo-head.component';
import { MonthDatePipe, HebrewWeekDaysPipe } from './pipes/month-date.pipe';
import { FormHelperService } from './services/form-helper.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NewTooltipDirective } from './directives/app-new-tooltip.directive';
import { TooltipDirective } from './directives/app-tooltip.directive';
import { AppLinkRolesDirective } from './directives/app-link-roles.directive';
import { AuthHandlerService } from './services/auth-handler.service';
import { DesktopDirective, MobileDirective } from './directives/responsive.directive';
import { PrePredictStrategy } from './services/pre-predict-strategy.service';
import { ReturnUrlService } from './services/return-url.service';
import { EloquaService } from './services/eloqua.service';
import { PrebootHelperModule, IfStableDirective } from 'core-max-lib';
import { AppDigitsOnlyDirective } from './directives/app-digits-only.directive';
import { SafeRichHtmlPipe } from './pipes/safe-rich-html.pipe';
import { NumberCurrencyPipe } from './pipes/number-currency.pipe';
import { DateHebrew } from './pipes/dateHebrew.pipe';
import { ScrollToTopComponent } from './components/scroll-to-top/scroll-to-top.component';
import { AppNumberWithoutSymbolDirective } from './directives/app-number-without-symbol.directive';
import { DetectorService } from './services/detector.service';
import { MaxLoaderComponent } from './components/max-loader/max-loader.component';
import { MaxLoaderNoOpacityComponent } from './components/max-loader-no-opacity/max-loader-no-opacity.component';
import { ExpandedParagraphComponent } from './components/expanded-paragraph/expanded-paragraph.component';
import { FourDigitsInputComponent } from './components/four-digits-input/four-digits-input.component';
import { DynamicPopupComponent } from './components/dynamic-popup/dynamic-popup.component';
import { AutocompleteOffDirective } from './directives/autocomplete-off.directive';
import { TurnPageComponent } from './components/turn-page/turn-page.component';
import { MaxLoaderTextComponent } from './components/max-loader-text/max-loader-text.component';
import { TrusteerSnippetsService } from './services/trusteer-snippets.service';
import { ErrorComponent } from './components/error-page/error-page.component';
import { LoaderWithTextComponent } from './components/loader-with-text/loader-with-text.component';
import { FlagDirective } from './directives/flag.directive';
import { FocusNextDirective } from './directives/focus-next.directive';
import { NgInitDirective } from './directives/ng-init.directive';
import { SsoLoginLoaderComponent } from './components/sso-login-loader/sso-login-loader.component';
import { MaxCurrencyNegativePricePipe } from './pipes/max-currency-negative-price.pipe';
import { MaxCurrencyNegativePriceSecPipe } from './pipes/max-currency-negative-price-sec.pipe';
import { NumToArrayPipe } from './pipes/num-to-array.pipe';
import { DisableCopyPasteDirective } from './directives/disable-copy-paste.directive';
import { AppFocusEndDirective } from './directives/app-focus-end.directive';
import { JoinHelperService } from "./services/join-helper.service";
import { ElementResolverDirective } from './directives/app-element-resolver.directive';
import { DynamicMatBottomSheetComponent } from './components/dynamic-mat-bottom-sheet/dynamic-mat-bottom-sheet.component'
import { ContentColorPipe } from './pipes/contentColor.pipe';
@NgModule({
    imports: [CommonModule, FormsModule, PrebootHelperModule, ReactiveFormsModule],
    declarations: [
        MobileMaskPipe,
        TelLinkPipe,
        SanitizeHtmlPipe,
        SanitizeResourceUrlPipe,
        SortPipe,
        FilterPipe,
        AppLinkRolesDirective,
        AppLinkDirective,
        AppLoaderDirective,
        AppLinkPreLoadDirective,
        AppNumberDirective,
        AppGtmDirective,
        AppLoadStyleDirective,
        ComadminDisabledDirective,
        BackgroundDirective,
        TooltipDirective,
        NewTooltipDirective,
        SeoHeadComponent,
        MonthDatePipe,
        HebrewWeekDaysPipe,
        DesktopDirective,
        MobileDirective,
        SafeRichHtmlPipe,
        AppDigitsOnlyDirective,
        NumberCurrencyPipe,
        ScrollToTopComponent,
        AppNumberWithoutSymbolDirective,
        MaxLoaderComponent,
        MaxLoaderNoOpacityComponent,
        FourDigitsInputComponent,
        DynamicPopupComponent,
        TurnPageComponent,
        AutocompleteOffDirective,
        MaxLoaderTextComponent,
        ErrorComponent,
        LoaderWithTextComponent,
        FlagDirective,
        FocusNextDirective,
        NgInitDirective,
        SsoLoginLoaderComponent,
        MaxCurrencyNegativePricePipe,
        MaxCurrencyNegativePriceSecPipe,
        DisableCopyPasteDirective,
        AppFocusEndDirective,
        DateHebrew,
        ExpandedParagraphComponent,
        ElementResolverDirective,
        DynamicMatBottomSheetComponent,
        ContentColorPipe
    ],
    exports: [
        MobileMaskPipe,
        TelLinkPipe,
        SanitizeHtmlPipe,
        SanitizeResourceUrlPipe,
        SortPipe,
        FilterPipe,
        AppLinkRolesDirective,
        AppLinkDirective,
        AppLoaderDirective,
        AppLinkPreLoadDirective,
        AppNumberDirective,
        AppGtmDirective,
        AppLoadStyleDirective,
        ComadminDisabledDirective,
        BackgroundDirective,
        TooltipDirective,
        NewTooltipDirective,
        SeoHeadComponent,
        MonthDatePipe,
        HebrewWeekDaysPipe,
        DesktopDirective,
        MobileDirective,
        IfStableDirective,
        AppDigitsOnlyDirective,
        SafeRichHtmlPipe,
        NumberCurrencyPipe,
        ScrollToTopComponent,
        AppNumberWithoutSymbolDirective,
        MaxLoaderComponent,
        MaxLoaderNoOpacityComponent,
        FourDigitsInputComponent,
        DynamicPopupComponent,
        TurnPageComponent,
        AutocompleteOffDirective,
        MaxLoaderTextComponent,
        ErrorComponent,
        LoaderWithTextComponent,
        FlagDirective,
        FocusNextDirective,
        NgInitDirective,
        SsoLoginLoaderComponent,
        MaxCurrencyNegativePricePipe,
        MaxCurrencyNegativePriceSecPipe,
        DisableCopyPasteDirective,
        AppFocusEndDirective,
        DateHebrew,
        ExpandedParagraphComponent,
        ElementResolverDirective,
        DynamicMatBottomSheetComponent,
        ContentColorPipe
    ],
    entryComponents: [DynamicPopupComponent],
    providers: [
        HttpService,
        UiHelperService,
        ContentsService,
        GeneralTextsService,
        GtmService,
        GAExtraDataService,
        FormHelperService,
        PrePredictStrategy,
        CurrencyPipe,
        DetectorService,
        NumToArrayPipe
    ]
})
export class CardholdersCoreModule {
    static forRoot(): ModuleWithProviders<CardholdersCoreModule> {
        return {
            ngModule: CardholdersCoreModule,
            providers: [
                ModuleTransferService,
                PopupsService,
                AuthService,
                AuthHandlerService,
                ReturnUrlService,
                EloquaService,
                TrusteerSnippetsService,
                JoinHelperService
            ]
        };
    }
}
