import { TypingFormat } from '../../shared/enums/typing-format.enum';

export const METADATA = [
    {
        key: 'personal-details',
        header: ' מזמינים כרטיס באתר ונהנים מפינוק מתנה ',
        id: 1,
        label: 'פרטים אישיים',
        type: 'group',
        controls: [
            {
                key: 'DetailsLabel',
                text: 'פרטים אישיים',
                type: 'label',
                order: 0.1
            },
            {
                key: 'KranotTypeCode',
                label: 'האם אתה עמית / בן/בת זוג / ילד',
                type: 'select-button',
                value: 0,
                options: [
                    {
                        label: 'עמית',
                        value: 0
                    },
                    {
                        label: 'בן/בת זוג',
                        value: 1
                    },
                    {
                        label: 'ילד',
                        value: 2
                    }
                ],
                required: true
            },
            {
                key: 'TeacherTypeCode',
                label: '',
                type: 'select-button',
                value: 0,
                options: [
                    {
                        label: 'חבר/ה בארגון המורים',
                        value: 0
                    },
                    {
                        label: 'בן/בת זוג',
                        value: 2
                    },
                    {
                        label: 'ילד/ילדה',
                        value: 3
                    }
                ],
                required: true
            },
            {
                key: 'InstituteId',
                label: 'לבחירת מוסד לימודים הקלד/י 2 אותיות',
                type: 'autocomplete',
                hint: {
                    toggleText: 'מה זה?',
                    text: 'אם לא מצאת את מוסד הלימודים שלך ברשימה יש לבחור בערך "מוסד שאינו ברשימה"'
                },
                required: true,
                validators: [
                    {
                        type: 'selectItemForceSelection',
                        errorMessage: 'נא לבחור ערך מהרשימה'
                    }
                ]
            },
            {
                key: 'IsicInstituteId',
                label: 'לבחירת מוסד לימודים הקלד/י 2 אותיות באנגלית',
                type: 'autocomplete',
                required: true,
                validators: [
                    {
                        type: 'selectItemForceSelection',
                        errorMessage: 'נא לבחור ערך מהרשימה'
                    }
                ]
            },
            {
                key: 'iTextChoiceCorrectIsicInstitution',
                type: 'itext',
                text: 'חשוב לוודא שמוסד הלימודים שבחרת הוא המוסד הנכון',
            },
            {
                key: 'PopulationId',
                label: 'מי אני?',
                type: 'dropdown',
                required: true,
                placeholder: 'בחירה'
            },
            {
                key: 'FirstName',
                label: 'שם פרטי, כמו בתעודת זהות ',
                type: 'textbox',
                hint: {
                    toggleText: 'מה זה?',
                    text: 'רק אותיות בעברית במטותא'
                },
                required: true,
                validators: [
                    {
                        type: 'pattern',
                        value: '([\u0590-\u05FF-\'" ]+$)',
                        errorMessage: 'רק אותיות בעברית במטותא'
                    },
                    {
                        type: 'maxlength',
                        value: 30,
                        errorMessage: 'יש להכניס שם פרטי באורך של עד 30 תווים'
                    },
                    {
                        type: 'invalidChar',
                        errorMessage: "שים לב הועבר תו שאינו תקין ( ' ) , יש להקליד מחדש."
                    }
                ],
                order: 1
            },
            {
                key: 'LastName',
                label: 'שם משפחה',
                type: 'textbox',
                required: true,
                validators: [
                    {
                        type: 'pattern',
                        value: '([\u0590-\u05FF-\'" ]+$)',
                        errorMessage: 'רק אותיות בעברית במטותא'
                    },
                    {
                        type: 'maxlength',
                        value: 30,
                        errorMessage: 'יש להכניס שם משפחה באורך של עד 30 תווים'
                    },
                    {
                        type: 'invalidChar',
                        errorMessage: "שים לב הועבר תו שאינו תקין ( ' ) , יש להקליד מחדש."
                    }
                ],
                order: 2
            },
            {
                key: 'IdNumber',
                label: 'תעודת זהות (עד 9 ספרות) ',
                type: 'textbox',
                keyboard: 'number',
                required: true,
                maxLength: 9,
                typingFormat: TypingFormat.IntegerNumbersOnly,
                validators: [
                    {
                        type: 'id',
                        errorMessage: 'מספר בן 9 ספרות, שכולן שייכות למספר הזהות שלך ;) '
                    },
                    {
                        type: 'notEqual',
                        errorMessage: 'תעודת הזהות של חבר מועדון קרנות אינה יכולה להיות תעודת הזהות של מבקש הכרטיס',
                        targetKey: 'IdNumberKranot'
                    },
                    {
                        type: 'notEqual',
                        errorMessage: 'תעודת הזהות של חבר ארגון המורים אינה יכולה להיות תעודת הזהות של מבקש הכרטיס',
                        targetKey: 'IdNumberTeacher'
                    },
                    {
                        type: 'notEqual',
                        errorMessage: 'תעודת הזהות של חבר מועדון ',
                        targetKey: 'AdditionalIdNumber'
                    }
                ],
                order: 3,
                hint: {
                    toggleText: 'מה זה?',
                    text: 'עד 9 ספרות, כולל ספרת ביקורת.'
                }
            },
            {
                key: 'Gender',
                label: 'מין',
                type: 'radioGroup',
                options: [
                    {
                        label: 'זכר',
                        value: '1'
                    },
                    {
                        label: 'נקבה',
                        value: '2'
                    }
                ],
                required: true,
                order: 4
            },
            {
                key: 'BirthDate',
                label: 'תאריך לידה',
                type: 'mask',
                required: true,
                keyboard: 'number',
                placeholder: 'DD/MM/YYYY',
                order: 5,
                validators: [
                    {
                        type: 'under18',
                        errorMessage: 'הכרטיס הוא לגילאי 18 ומעלה - נשמח לראות אותך עוד כמה שנים.'
                    },
                    {
                        type: 'isFutureDate',
                        errorMessage: 'כנראה שלא נולדתם בעתיד...אנא הזינו תאריך לידה תקין.'
                    },
                    {
                        type: 'invalidDate',
                        errorMessage: 'התאריך לא תקין, וכדי שלא יהיה ספק:DD/MM/YYYY'
                    },
                    {
                        type: 'over110',
                        errorMessage: 'תאריך לידה – ערך שהוזן שגוי'
                    }
                ]
            },
            {
                key: 'CellularPhoneNumber',
                label: 'טלפון נייד',
                type: 'textbox',
                keyboard: 'number',
                required: true,
                maxLength: 10,
                typingFormat: TypingFormat.IntegerNumbersOnly,
                validators: [
                    {
                        type: 'maxlength',
                        value: 10,
                        errorMessage: 'ככה מספר הטלפון צריך להראות: 0522345678. <br> 05 בהתחלה ובלי מקף.'
                    },
                    {
                        type: 'minlength',
                        value: 10,
                        errorMessage: 'ככה מספר הטלפון צריך להראות: 0522345678. <br> 05 בהתחלה ובלי מקף.'
                    },
                    {
                        type: 'cellPhonePrefixInvalid',
                        value: '05',
                        errorMessage: 'ככה מספר הטלפון צריך להראות: 0522345678. <br> 05 בהתחלה ובלי מקף.'
                    },
                    {
                        type: 'pattern',
                        value: '^05[0-9]{8}$',
                        errorMessage: 'ככה מספר הטלפון צריך להראות: 0522345678. <br> 05 בהתחלה ובלי מקף.'
                    },
                    {
                        type: 'equalPhone',
                        errorMessage: 'מספרי הנייד שמילאת לא זהים',
                        targetKey: 'CellularPhoneNumberConfirmation'
                    }
                ],
                order: 6
            },
            {
                key: 'CellularPhoneNumberConfirmation',
                label: 'טלפון נייד בשנית בבקשה',
                type: 'textbox',
                keyboard: 'number',
                required: true,
                maxLength: 10,
                typingFormat: TypingFormat.IntegerNumbersOnly,
                disablePaste: true,
                validators: [
                    {
                        type: 'maxlength',
                        value: 10,
                        errorMessage: 'ככה מספר הטלפון צריך להראות: 0522345678. <br> 05 בהתחלה ובלי מקף.'
                    },
                    {
                        type: 'minlength',
                        value: 10,
                        errorMessage: 'ככה מספר הטלפון צריך להראות: 0522345678. <br> 05 בהתחלה ובלי מקף.'
                    },
                    {
                        type: 'cellPhonePrefixInvalid',
                        value: '05',
                        errorMessage: 'ככה מספר הטלפון צריך להראות: 0522345678. <br> 05 בהתחלה ובלי מקף.'
                    },
                    {
                        type: 'pattern',
                        value: '^05[0-9]{8}$',
                        errorMessage: 'ככה מספר הטלפון צריך להראות: 0522345678. <br> 05 בהתחלה ובלי מקף.'
                    },
                    {
                        type: 'equalPhone',
                        errorMessage: 'מספרי הנייד שמילאת לא זהים',
                        targetKey: 'CellularPhoneNumber'
                    }
                ],
                order: 6.1
            },
            {
                key: 'HomePhoneNumber',
                label: 'טלפון בבית (רשות)',
                type: 'textbox',
                keyboard: 'number',
                required: false,
                maxLength: 10,
                typingFormat: TypingFormat.IntegerNumbersOnly,
                validators: [
                    {
                        type: 'pattern',
                        value: '^[0-9]{9,10}$',
                        errorMessage: 'יש להכניס מספר בן 9 עד 10 ספרות, ללא מקף'
                    }
                ],
                order: 7
            },
            {
                key: 'Email',
                label: 'מייל שיעודכן בחשבונך ב max',
                extraLabel: '*אם כבר יש לך חשבון ב Bit of Gold יש להזין את אותו מייל שמעודכן בחשבונך ב Bits of Gold',
                type: 'textbox',
                keyboard: 'email',
                required: true,
                validators: [
                    {
                        type: 'pattern',
                        value: '^[A-Za-z0-9._%-]+@(?:[a-zA-Z0-9-]+\\.)+[a-zA-Z]{1,4}$',
                        errorMessage: 'יונת הדוא"ל מוסרת שהמייל לא תקין'
                    }
                ],
                order: 8
            },
            {
                key: 'WorkplaceEmail',
                label: 'מייל ארגוני של עובד החברה',
                type: 'textbox',
                keyboard: 'email',
                required: true,
                validators: [
                    {
                        type: 'pattern',
                        value: '^[A-Za-z0-9._%-]+@(?:[a-zA-Z0-9-]+\\.)+[a-zA-Z]{1,4}$',
                        errorMessage: 'יונת הדוא"ל מוסרת שהמייל לא תקין'
                    }
                ],
                order: 8.01
            },
            {
                key: 'IdNumberKranot',
                label: 'תעודת זהות של מבקש הכרטיס ',
                type: 'textbox',
                keyboard: 'number',
                required: true,
                maxLength: 9,
                typingFormat: TypingFormat.IntegerNumbersOnly,
                validators: [
                    {
                        type: 'id',
                        errorMessage: 'סליחה, אבל אין מצב שזהו מספר תעודת זהות תקין '
                    },
                    {
                        type: 'notEqual',
                        errorMessage: 'תעודת הזהות של חבר מועדון קרנות אינה יכולה להיות תעודת הזהות של מבקש הכרטיס',
                        targetKey: 'IdNumber'
                    }
                ],
                order: 8.05,
                hint: {
                    toggleText: 'מה זה?',
                    text: 'עד 9 ספרות, כולל ספרת ביקורת.'
                }
            },
            {
                key: 'IdNumberTeacher',
                label: 'תעודת זהות של מבקש הכרטיס ',
                type: 'textbox',
                keyboard: 'number',
                required: true,
                maxLength: 9,
                typingFormat: TypingFormat.IntegerNumbersOnly,
                validators: [
                    {
                        type: 'id',
                        errorMessage: 'סליחה, אבל אין מצב שזהו מספר תעודת זהות תקין '
                    },
                    {
                        type: 'notEqual',
                        errorMessage: 'תעודת הזהות של חבר ארגון המורים אינה יכולה להיות תעודת הזהות של מבקש הכרטיס',
                        targetKey: 'IdNumber'
                    }
                ],
                order: 8.05,
                hint: {
                    toggleText: 'מה זה?',
                    text: 'עד 9 ספרות, כולל ספרת ביקורת.'
                }
            },
            {
                key: 'AdditionalIdNumber',
                label: 'תעודת זהות של מבקש הכרטיס ',
                type: 'textbox',
                keyboard: 'number',
                required: true,
                maxLength: 9,
                typingFormat: TypingFormat.IntegerNumbersOnly,
                validators: [
                    {
                        type: 'id',
                        errorMessage: 'סליחה, אבל אין מצב שזהו מספר תעודת זהות תקין '
                    },
                    {
                        type: 'notEqual',
                        errorMessage: 'תעודת הזהות של חבר מועדון ',
                        targetKey: 'IdNumber'
                    }
                ],
                order: 8.05,
                hint: {
                    toggleText: 'מה זה?',
                    text: 'עד 9 ספרות, כולל ספרת ביקורת.'
                }
            },
            {
                key: 'iTextDetailsNotCryptoCardSuperK',
                type: 'itext',
                text: 'אם הפרטים שמילאת תקינים, ובמקרה שלא נצליח להשלים את התהליך, נדאג ליצור אתך קשר',
                order: 8.1
            },
            {
                key: 'iTextDetailsCryptoCardSuperK',
                type: 'itext',
                text: 'הפרטים נאספים תוך כדי הקלדתם כדי שנוכל לפנות אליך ולסייע לך להשלים את התהליך במקרה הצורך',
                order: 8.1
            },
            {
                key: 'iText',
                type: 'itext',
                text: 'הזמנת הכרטיס מצריכה את אישור תנאי ההצטרפות ואת האישור מצדנו ב-max',
                order: 8.1
            },
            {
                key: 'iText',
                type: 'itext',
                text:
                    // tslint:disable-next-line:max-line-length
                    'max תפנה ללשכת האשראי כדי לקבל חוות דעת בנוגע למתן אשראי עבורך. לשכת האשראי תגיש לבנק ישראל בקשה לקבלת נתוני אשראי לגביך, שנמצאים במאגר נתוני האשראי בבנק ישראל.',
                order: 8.2
            },
            {
                key: 'iTextForAllClub',
                type: 'itext',
                textWithHtml:
                    'המידע אודותייך ישמש את מקס ומי מטעמה כמפורט במדיניות הפרטיות באתר all-club, לרבות לצורך אספקת השירות, תפעולו, מחקר וסטטיסטיקה ומשלוח פרסומים (גם מותאמים אישית) באמצעות מסרון או דוא"ל. ניתן בכל עת לפנות ל<a href="mailto:info@allclub.co.il">info@allclub.co.il</a> או לפעול כמפורט בדיוור כדי לבקש שלא לקבל הודעות כאמור. אין חובה חוקית למסור את המידע. האמור יחול גם אם לא תשלים את תהליך ההצטרפות',
                order: 8.3
            },
            {
                key: 'iTextForLoreal',
                type: 'itext',
                textWithHtml:
                    'המידע אודותייך ישמש את מקס ומי מטעמה כמפורט במדיניות הפרטיות באתר לוריאל, לרבות לצורך אספקת השירות, תפעולו, מחקר וסטטיסטיקה ומשלוח פרסומים (גם מותאמים אישית) באמצעות מסרון או דוא"ל. ניתן בכל עת לפנות ל: <a href="mailto:kerastaseil@loreal.com">kerastaseil@loreal.com</a> או לפעול כמפורט בדיוור כדי לבקש שלא לקבל הודעות כאמור. אין חובה חוקית למסור את המידע. האמור יחול גם אם לא תשלים את תהליך ההצטרפות',
                order: 8.4
            },
            {
                key: 'iTextAsteriskForCryptoCardSuperK',
                type: 'itext',
                textWithHtml:
                    'המידע שמסרתי יעבור ל Bits of Gold בע"מ לצורך בדיקת זכאות לפתיחת חשבון ב Bits of Gold ופתיחת החשבון במקרה של זכאות. בנוסף, Bits of Gold בע"מ תעביר לmax מידע, ככל שנדרש, לצורך השלמת בדיקת הזכאות. מעבר לכך, ידוע לי כי max ו Bits of Gold יחליפו ביניהן מידע אודותיי במסגרת ניהול ותפעול הכרטיס והשירותים הניתנים על ידי Bits of Gold, לרבות לצורך שיקוף המידע בנכסים הדיגיטליים של max אודות המטבעות הווירטואליים שנצברו עבורי. מידע נוסף אודות השימוש של Bits of Gold ו max במידע נמצא ב<a href ="https://www.max.co.il/general/pages/privacy-2020" target="_blank">מדיניות הפרטיות של max</a> וב<a href ="https://www.bitsofgold.co.il/terms_max" target="_blank">מדיניות הפרטיות של Bits of Gold</a>',
                order: 8.5
            },
            {
                key: 'CheckEntitlementForCard',
                type: 'checkbox',
                customTextTitle: 'לצורך בדיקת זכאותי להנפקת כרטיס:',
                customText:
                    'אישרתי שהפרטים והנתונים עלי יועברו לחברת MAX ושהיא רשאית לבדוק נתונים אלו וכל נתון אחר עליי.',
                value: false,
                required: true,
                order: 9
            },
            {
                key: 'CheckEntitlementForDreamCard',
                type: 'checkbox',
                customTextTitle: 'לצורך בדיקת זכאותי להנפקת כרטיס:',
                customText:
                    'אני מסכים/ה ומאשר/ת שפרטים ונתונים אודותיי יועברו לחברת max אשר תהיה רשאית לבדוק את הנתונים וכל נתון אחר לגביי.\n' +
                        // tslint:disable-next-line:max-line-length
                        'אני מסכים/ה ומאשר/ת שפרטים ונתונים אודותיי יועברו לחברת FOX לצורך צירופי למועדון Dream Card. במידה ואיני חבר/ת מועדון Dream Card ידוע לי שעלות ההצטרפות הינה 69 ש"ח',
                value: false,
                required: true,
                order: 9
            },
            {
                key: 'CheckEntitlementForWinCard',
                type: 'checkbox',
                customTextTitle: 'לצורך בדיקת זכאותי להנפקת כרטיס:',
                customText:
                    'אני מסכים/ה ומאשר/ת שפרטים ונתונים אודותיי יועברו לחברת max אשר תהיה רשאית לבדוק את הנתונים וכל נתון אחר לגביי.\n' +
                        // tslint:disable-next-line:max-line-length
                        'אני מסכים/ה ומאשר/ת שפרטים ונתונים אודותיי יעברו לחברת כ.נ. מחסני השוק לצורך צירופי למועדון +WINCARD.',
                value: false,
                required: true,
                order: 9
            },,
            {
                key: 'TransDataToVictoryAllowInd',
                type: 'checkbox',
                customText:
                    'אני מאשר/ת ומסכימ/ה לקבל דיוור ישיר, הודעות ופרסומים שיווקיים באמצעות דוא"ל, מסרונים (SMS),' +
                        ' מסרים מידיים, טלפון או בכל אמצעי תקשורת אחר, וכן פניות בשיחות שיווקיות, מויקטורי או מי מטעמה ושותפיה העסקיים, במהלך ואחרי חברותי במועדון וגם אם מספר הטלפון שלי מופיע במאגר "אל תתקשר אליי".',
                value: false,
                required: false,
                order: 9
            },
            {
                key: 'TransDataTo1824AllowInd',
                type: 'checkbox',
                customTextTitle: 'דיוור ישיר וחומרים שיווקיים – שמונה עשרה עשרים וארבע מועדון צרכנות דיגיטלי בע"מ',
                customText:
                    'אני מאשר/ת ומסכים/ה לקבל דיוור ישיר, הודעות ופרסומים שיווקיים באמצעות דוא"ל מסרונים (SMS), פקס, מערכות חיוג אוטומטי ו/ או בכל אמצעי תקשורת אלקטרוני אחר משמונה עשרה עשרים וארבע, מועדון צרכנות דיגיטלי בע"מ.',
                value: false,
                required: false,
                order: 9
            },
            {
                key: 'TransferDataToFoxAllowInd',
                type: 'checkbox',
                customText:
                    // tslint:disable-next-line:max-line-length
                    'אני מאשר ומסכים לקבלת דיוור ישיר, הודעות  ופרסומים שיווקיים באמצעות דוא"ל, מסרונים (SMS),  פקס, מערכות חיוג אוטומטי ו/או בכל אמצעי תקשורת  אלקטרוני אחר מקבוצת פוקס',
                value: false,
                required: false,
                order: 9.1
            },
            {
                key: 'TransDataToHashukAllowInd',
                type: 'checkbox',
                customText:
                    // tslint:disable-next-line:max-line-length
                    'אני מאשר/ת ומסכים/ה לקבלת דיוור ישיר, הודעות ופרסומים שיווקיים באמצעות דוא"ל, מסרונים (SMS), פקס, מערכות חיוג אוטומטי ו/או בכל אמצעי תקשורת אלקטרוני אחר מחברת כ.נ מחסני השוק.',
                value: false,
                required: false,
                order: 9.1
            },
            {
                key: 'TransDataToClalAllowInd',
                type: 'checkbox',
                customText: "  אני מאשר/מאשרת לחברות מקבוצת כלל ביטוח ופיננסים להציע לי מעת לעת מוצרים ושירותים שונים (לרבות מוצרי ביטוח והטבות לחברי מועדון כלל pay) בהתאם למידע אודותיי שישולב מכל חברות הקבוצה, לרבות מידע שיתקבל ממקס בגין כרטיס המועדון ושימושי בו. ההצעות יישלחו בין השאר בדואר אלקטרוני, במסרונים ובוואטסאפ, ובדיוור ישיר בכל אמצעי התקשורת. אפשר לשנות את הבחירה לגבי קבלת הצעות כאלה בטלפון 5454* ",
                value: false,
                required: false,
                order: 9.1
            },
            {
                key: 'DivurYashirSpamInd',
                type: 'checkbox',
                customText:
                    // tslint:disable-next-line:max-line-length
                    'אני מאשר ומסכים להוראות סעיפים 11.9(ג) ו-12 להסכם ההצטרפות ומאשר למקס לשמור ולהשתמש במידע אודותיי לשם משלוח דיוור ישיר, הודעות ופרסומים שיווקיים באמצעות דוא"ל, מסרונים (SMS), מערכת חיוג אוטומטית ובכל אמצעי תקשורת אחר. בנוסף, בקשר למידע אישי הנאסף בקשר לפעילות ועסקאות בתחומי האיחוד האירופאי כמפורט בהודעת הפרטיות הגלובאלית של מקס, אני מאשר ומסכים שמקס תאסוף ותעבד מידע אישי בקשר לפעילות באירופה על מנת להציע לי פרסום מותאם אישית, לרבות הטבות, הצעות, ופינוקים באיחוד האירופאי.',
                //value: false,
                required: false,
                order: 9.2
            },
            {
                key: 'CheckEntitlementForAllClubCard',
                type: 'checkbox',
                customText:
                    'אני מאשר ל-max להעביר את המידע אודותיי לall-club לצרכי ניהול מועדון וכמפורט במדיניות הפרטיות שלה, וזאת גם אם לא אשלים הצטרפותי למועדון',
                value: false,
                required: false,
                order: 9.3
            },
            {
                key: 'CheckEntitlementForLorealCard',
                type: 'checkbox',
                customText:
                    'אני מאשר ל-max להעביר את המידע אודותיי ללוריאל לצרכי ניהול מועדון וכמפורט במדיניות הפרטיות שלה, וזאת גם אם לא אשלים הצטרפותי למועדון',
                value: false,
                required: false,
                order: 9.4
            }
        ]
    },
    {
        key: 'address',
        header: ' עוד כמה פרטים ונכיר כבר ממש טוב ',
        id: 2,
        label: 'בית ופרטי חשבון',
        type: 'group',
        controls: [
            {
                key: 'AddressLabel',
                type: 'label',
                text: 'כתובת מגורים',
                order: 0
            },
            {
                key: 'AddressDisabledLabel',
                type: 'label',
                text: 'זאת לא הכתובת שלך? בסיום התהליך אפשר לעדכן כתובת באתר או לשוחח עם מוקד השירות שלנו',
                order: 0.1
            },
            {
                key: 'HomeCity',
                label: 'יישוב',
                type: 'autocomplete',
                dropdown: false,
                required: true,
                disablePaste: true,
                order: 1,
                maxLength: 20,
                validators: [
                    {
                        type: 'selectItemPatternFree',
                        value: '(^[\u0590-\u05FF .()\'"0-9-]{1,20})$',
                        errorMessage: 'ניתן להקליד אותיות בעברית בלבד.'
                    }
                ]
            },
            {
                key: 'HomeStreet',
                label: 'רחוב',
                extraLabel: '*לא נמצא ברשימה? אפשר גם להקליד',
                type: 'autocomplete',
                dropdown: false,
                required: false,
                disablePaste: true,
                order: 2,
                maxLength: 19,
                validators: [
                    {
                        type: 'selectItemPatternFree',
                        value: '(^[\u0590-\u05FF .()\'"0-9-]{0,19})$',
                        errorMessage: 'ניתן להקליד אותיות בעברית בלבד.'
                    }
                ]
            },
            {
                key: 'HomeHouseNum',
                label: 'מספר בית',
                type: 'textbox',
                keyboard: 'number',
                maxLength: 4,
                typingFormat: TypingFormat.IntegerNumbersOnly,
                validators: [
                    {
                        type: 'pattern',
                        value: '^[0-9]{0,4}',
                        errorMessage: 'יש להכניס ספרות בלבד'
                    }
                ],
                order: 3
            },
            {
                key: 'HomeEntrance',
                label: 'כניסה',
                type: 'textbox',
                maxLength: 1,
                validators: [
                    {
                        type: 'pattern',
                        value: '^[\u05D0-\u05D9\u05DB-\u05DC\u05DE\u05E0-\u05E2\u05E4\u05E6-\u05EA0-9]{1}$',
                        errorMessage: 'הערך בשדה כניסה צריך להיות תו אחד (אות או ספרה)'
                    }
                ],
                order: 5
            },
            {
                key: 'HomeApartment',
                label: 'דירה',
                type: 'textbox',
                keyboard: 'number',
                maxLength: 2,
                typingFormat: TypingFormat.IntegerNumbersOnly,
                validators: [
                    {
                        type: 'pattern',
                        value: '^[0-9]{0,2}',
                        errorMessage: 'נא להזין עד 2 ספרות בלבד'
                    }
                ],
                order: 4
            },
            {
                key: 'postToSameAddress',
                label: 'שנשלח דואר לכתובת שנתת?',
                type: 'switch',
                disabled: false,
                value: true,
                options: [
                    {
                        text: 'כן',
                        value: true
                    },
                    {
                        text: 'לא',
                        value: false
                    }
                ],
                order: 6
            },
            {
                key: 'PostLabel',
                type: 'label',
                text: 'כתובת למשלוח דואר',
                order: 7,
                visible: false
            },
            {
                key: 'IfPostBox',
                type: 'checkbox',
                text: 'יש לי ת.ד',
                value: false,
                order: 8,
                visible: false
            },
            {
                key: 'MailPob',
                label: 'מספר ת.ד',
                type: 'textbox',
                keyboard: 'number',
                validators: [
                    {
                        type: 'pattern',
                        value: '^[0-9]{0,5}',
                        errorMessage: 'תיבת דואר - אנא הזן ספרות בלבד'
                    }
                ],
                order: 9,
                visible: false
            },
            {
                key: 'MailCity',
                label: 'יישוב',
                type: 'autocomplete',
                dropdown: false,
                order: 10,
                visible: false,
                required: true,
                disablePaste: true,
                maxLength: 20,
                validators: [
                    {
                        type: 'selectItemPatternFree',
                        value: '(^[\u0590-\u05FF .()\'"0-9-]{1,20})$',
                        errorMessage: 'ניתן להקליד אותיות בעברית בלבד.'
                    }
                ]
            },
            {
                key: 'MailStreet',
                label: 'רחוב',
                type: 'autocomplete',
                dropdown: false,
                order: 11,
                visible: false,
                required: false,
                disablePaste: true,
                maxLength: 19,
                validators: [
                    {
                        type: 'selectItemPatternFree',
                        value: '(^[\u0590-\u05FF .()\'"0-9-]{0,19})$',
                        errorMessage: 'ניתן להקליד אותיות בעברית בלבד.'
                    }
                ]
            },
            {
                key: 'MailHouseNum',
                label: 'מספר בית',
                type: 'textbox',
                keyboard: 'number',
                maxLength: 4,
                typingFormat: TypingFormat.IntegerNumbersOnly,
                validators: [
                    {
                        type: 'pattern',
                        value: '^[0-9]{0,4}',
                        errorMessage: 'יש להכניס ספרות בלבד'
                    }
                ],
                order: 12,
                visible: false
            },
            {
                key: 'MailEntrance',
                label: 'כניסה',
                type: 'textbox',
                maxLength: 1,
                validators: [
                    {
                        type: 'pattern',
                        value: '^[\u05D0-\u05D9\u05DB-\u05DC\u05DE\u05E0-\u05E2\u05E4\u05E6-\u05EA0-9]{1}$',
                        errorMessage: 'הערך בשדה כניסה צריך להיות תו אחד (אות או ספרה)'
                    }
                ],
                order: 13,
                visible: false
            },
            {
                key: 'MailApartment',
                label: 'דירה',
                type: 'textbox',
                keyboard: 'number',
                maxLength: 2,
                typingFormat: TypingFormat.IntegerNumbersOnly,
                validators: [
                    {
                        type: 'pattern',
                        value: '^[0-9]{0,2}',
                        errorMessage: 'נא להזין עד 2 ספרות בלבד'
                    }
                ],
                order: 12.1,
                visible: false
            },
            {
                key: 'CreditCardDetailsLabel',
                type: 'label',
                text: 'פרטים על כרטיס האשראי',
                order: 14
            },
            {
                key: 'MutagCode',
                label: 'מותג',
                type: 'radioGroup',
                icons: true,
                value: 'M',
                options: [
                    {
                        label: 'ויזה',
                        value: 'V'
                    },
                    {
                        label: 'מסטרקארד',
                        value: 'M'
                    }
                ],
                required: true,
                order: 15,
                hint: {
                    toggleText: 'מה זה?',
                    text:
                        'ויזה ומאסטרקארד הן חברות האשראי העולמיות הגדולות בעולם. מותג ויזה, יועד במקור לשוק האמריקני ולכן גם מתבסס על מטבע הדולר בחישובי המרה שונים. חברת מאסטרקארד יועדה במקור לשוק האירופי ולכן יחס ההמרה של החברה מתבסס על מטבע היורו. כיום שתי החברות מעניקות שירותי אשראי בכל העולם.'
                }
            },
            {
                key: 'MutagCodeLabel',
                type: 'label',
                icon: true,
                text: '',
                iconName: '',
                order: 15
            },
            {
                key: 'BillCycleCode',
                label: 'באיזה תאריך היית רוצה שהחיוב יהיה?',
                type: 'dropdown',
                placeholder: 'בחירה',
                required: true,
                order: 16
            },
            {
                key: 'BankAccountDetailsLabel',
                type: 'label',
                text: 'פרטי חשבון הבנק',
                order: 17
            },
            {
                key: 'BankId',
                label: 'שם הבנק',
                type: 'dropdown',
                required: true,
                placeholder: 'בחירה',
                order: 18
            },
            {
                key: 'BranchId',
                label: 'מספר הסניף',
                type: 'textbox',
                keyboard: 'number',
                required: true,
                maxLength: 3,
                typingFormat: TypingFormat.IntegerNumbersOnly,
                validators: [
                    {
                        type: 'pattern',
                        value: '[0-9]{1,3}',
                        errorMessage: 'ספרות בלבד. תודה '
                    }
                ],
                order: 19
            },
            {
                key: 'AccountNumber',
                label: 'מספר החשבון ',
                type: 'textbox',
                keyboard: 'number',
                required: true,
                maxLength: 9,
                typingFormat: TypingFormat.IntegerNumbersOnly,
                validators: [
                    {
                        type: 'pattern',
                        value: '([0-9]){1,9}',
                        errorMessage: 'אין כזה חשבון בנק. יש להכניס ספרות בלבד. תודה'
                    }
                ],
                hint: {
                    toggleText: 'מה זה?',
                    text: 'מספר החשבון בסניף הבנק שלך ממנו יחוייב הכרטיס'
                },
                order: 20
            },
            {
                key: 'AccountOwnerName',
                label: 'שם בעל החשבון',
                type: 'textbox',
                required: true,
                validators: [
                    {
                        type: 'pattern',
                        value: '([\u0590-\u05FF-\'" ]+$)',
                        errorMessage: 'עברית שפה יפה. יש להכניס אותיות בעברית בלבד.'
                    },
                    {
                        type: 'maxlength',
                        value: 50,
                        errorMessage: 'יש להכניס שם באורך של עד 50 תווים'
                    }
                ],
                order: 21
            },
            {
                key: 'AccountOtherClientInd',
                label: 'האם יש לך שותף או שותפה לחשבון שאינם קרובים מדרגה ראשונה? ',
                type: 'switch',
                value: false,
                options: [
                    {
                        text: 'כן',
                        value: true
                    },
                    {
                        text: 'לא',
                        value: false
                    }
                ],
                required: true,
                hint: {
                    toggleText: 'מה זה?',
                    text:
                        'סמן "לא"-אם החשבון שייך לך בלבד,או אם השותף לחשבון הוא מקרבה מדרגה ראשונה (השותף לחשבון הוא בן/ת זוג,הורה,ילד).'
                },
                order: 23
            },
            {
                key: 'AdditionOwnerDetailsLabel',
                type: 'label',
                text: 'פרטי בעל החשבון האחר',
                order: 24,
                visible: false
            },
            {
                key: 'AccountOtherClientFirstName',
                label: 'שם פרטי',
                type: 'textbox',
                required: true,
                order: 25,
                visible: false,
                validators: [
                    {
                        type: 'pattern',
                        value: '([\u0590-\u05FF-\'" ]+$)',
                        errorMessage: 'עברית שפה יפה. יש להכניס אותיות בעברית בלבד.'
                    },
                    {
                        type: 'maxlength',
                        value: 30,
                        errorMessage: 'יש להכניס שם פרטי באורך של עד 30 תווים'
                    }
                ]
            },
            {
                key: 'AccountOtherClientLastName',
                label: 'שם משפחה',
                type: 'textbox',
                required: true,
                order: 26,
                visible: false,
                validators: [
                    {
                        type: 'pattern',
                        value: '([\u0590-\u05FF-\'" ]+$)',
                        errorMessage: 'עברית שפה יפה. יש להכניס אותיות בעברית בלבד.'
                    },
                    {
                        type: 'maxlength',
                        value: 30,
                        errorMessage: 'יש להכניס שם משפחה באורך של עד 30 תווים'
                    }
                ]
            },
            {
                key: 'AccountOtherClientIdNumber',
                label: 'תעודת זהות',
                type: 'textbox',
                keyboard: 'number',
                required: true,
                order: 27,
                visible: false,
                maxLength: 9,
                typingFormat: TypingFormat.IntegerNumbersOnly,
                validators: [
                    {
                        type: 'id',
                        errorMessage: 'אופס תקלונת. יש להכניס שוב רק ספרות ולקוות לטוב.'
                    }
                ]
            },
            {
                key: 'AdditionalCreditCardLabel',
                type: 'label',
                text: 'כרטיס אשראי',
                order: 28
            },
            {
                key: 'CardInd',
                label: 'האם יש בבעלותך כרטיס אשראי של חברה כלשהי (לא כולל כרטיס דביט)',
                type: 'switch',
                value: true,
                options: [
                    {
                        text: 'כן',
                        value: true
                    },
                    {
                        text: 'לא',
                        value: false
                    }
                ],
                required: true,
                order: 29
            },
            {
                key: 'BankCreditLimit',
                label: 'גובה מסגרת האשראי של כרטיס האשראי הבנקאי שברשותך ',
                type: 'textbox',
                keyboard: 'number',
                required: false,
                hint: {
                    toggleText: 'מה זה?',
                    text: 'כרטיס האשראי הבנקאי הוא כרטיס האשראי שעליו מוטבע הלוגו של הבנק שלך'
                },
                order: 30,
                visible: false,
                maxLength: 8,
                typingFormat: TypingFormat.IntegerNumbersOnly,
                validators: [
                    {
                        type: 'pattern',
                        value: '^[0-9]+$',
                        errorMessage: 'יש להכניס ספרות בלבד.'
                    }
                ]
            },
            {
                key: 'CheckBoxApproveMaxIdForBOG',
                type: 'checkbox',
                customTextTitle:
                    'אני מאשר.ת ל max להעביר את מספר ת"ז והמייל שלי לאתר Bits Of Gold לטובת בדיקת זכאותי לפתיחת חשבון',
                value: false,
                //     required: true,
                visible: false,
                customRequiredErrorMessage: 'יש לאשר את העברת הפרטים',
                order: 31,
                validators: [
                    {
                        type: 'requiredTrue', // required error
                        value: true,
                        errorMessage: 'יש לאשר את העברת הפרטים'
                    }
                ]
            },
            {
                key: 'TransDataThirdPartyAlwIndToClal',
                type: 'checkbox',
                customText: 'אני מסכים/ה שהמידע שמסרתי, ומידע שנובע משימושי בכרטיס, ובכלל זאת היקף העסקאות ומוצרי האשראי בכרטיס יועבר לחברות ואורגנים בקבוצת כלל ביטוח ופיננסים. מידע זה, לרבות מידע שבידן ומידע שיגיע אליהן, יישמר במערכות של קבוצת כלל וישמש לצורכי בקרה ותפעול המועדון, אספקת שירותים, ניהול עסקים, תגמול סוכן/ת הביטוח ששיווק את הכרטיס (ככל שהכרטיס שווק באמצעות סוכן/ת), עמידה בהוראות החוק, פיתוח עסקי כולל מתן הצעות שיווקיות ולהגנה על זכויותיהן. המידע יועבר על ידי קבוצת כלל גם לגורמים המספקים לה שירותים (כמו ספקי טכנולוגיה וספקי הטבות שונים), ולגורמים אחרים לפי דין, וישמש אותם למטרות כאמור לעיל. מידע נוסף נמצא במדיניות הפרטיות של קבוצת כלל בכתובת <a href="www.clalbit.co.il/policy">www.clalbit.co.il/policy</a><br><u>במידה והצטרפתי למועדון באמצעות סוכן ביטוח,</u>  אני מסכים/ה, בנוסף, שהמידע שמסרתי, ומידע על סך החיובים ומוצרי האשראי בכרטיס (ללא פירוט העסקאות) יועבר לסוכן/ת הביטוח אשר שיווק לי את כרטיס האשראי, לצורך בקרה אחר תגמול סוכן / ת הביטוח כאמור.',
                value: false,
                visible: false,
                customRequiredErrorMessage: 'יש לאשר את העברת הפרטים',
                order: 31,
                validators: [
                    {
                        type: 'requiredTrue', // required error
                        value: true,
                        errorMessage: 'יש לאשר את העברת הפרטים'
                    }
                ]
            }
            ,
            {
                key: 'AgreeToPayMembershipIsicInd',
                type: 'checkbox',
                customTextTitle: 'אני יודע.ת ומאשר.ת שהזמנת הכרטיס מותנית בתשלום דמי הצטרפות למועדון ISIC, בסך 30 ₪ שייגבו לאחר הפעלת הכרטיס. התשלום הינו עבור 3 שנים חברות במועדון ISIC. עוד ידוע לי כי החברות במועדון ISIC מותנית בחידוש החברות בתום ה-3 השנים, תוך תשלום של דמי הצטרפות שנתיים בסך של 30 ₪ או כפי שיעודכן מעת לעת ואלו יגבו ישירות על ידי יוניק. אי תשלום דמי ההצטרפות, יוביל לביטול החברות במועדון ISIC וביטול הטבות החברות.',
                value: false,
                visible: false,
                customRequiredErrorMessage: 'יש לאשר את התשלום',
                order: 31,
                validators: [
                    {
                        type: 'requiredTrue', // required error
                        value: true,
                        errorMessage: 'יש לאשר את התשלום'
                    }
                ]
            }
        ]
    },
    {
        key: 'occupation-details',
        header: 'מתקדמים! הגענו לשלב 3',
        id: 3,
        label: 'עבודה',
        type: 'group',
        controls: [
            {
                key: 'WorkLabel',
                type: 'label',
                text: 'כמה פרטים על העבודה שלך',
                order: 0
            },
            {
                key: 'WorkStatusCode',
                label: 'סטטוס תעסוקתי',
                type: 'dropdown',
                required: true,
                placeholder: 'בחירה',
                order: 1
            },
            {
                key: 'FinanceOriginCode',
                label: 'אם לא מעבודה, מאיפה בעצם מגיעים הכספים לחשבון ממנו הכרטיס מחויב?',
                type: 'dropdown',
                required: true,
                visible: false,
                placeholder: 'בחירה',
                order: 1.1
            },
            {
                key: 'MyNetIncomeCode',
                label: 'הכנסה חודשית נטו בש"ח למשפחה ',
                type: 'dropdown',
                required: true,
                placeholder: 'ההכנסה החודשית המשפחתית (נטו)',
                hint: {
                    toggleText: 'מה זה?',
                    text:
                        'הכנסה חודשית משותפת נטו של כל השותפים במשק הבית, לא כולל חיות מחמד (כולל הכנסות מרכוש,פנסיה וכד\').'
                },
                order: 2
            },
            {
                key: 'EducationCode',
                label: 'השכלה',
                type: 'dropdown',
                required: true,
                placeholder: 'בחירה',
                order: 3
            },
            {
                key: 'CompanyName',
                label: 'שם מקום העבודה',
                type: 'textbox',
                required: true,
                order: 4,
                validators: [
                    {
                        type: 'maxlength',
                        value: 50,
                        errorMessage: 'יש להכניס שם מקום העבודה באורך של עד 50 תווים'
                    }
                ]
            },
            {
                key: 'CreditCardDetailsLabel',
                type: 'label',
                text: 'פרטים על כרטיס אשראי קיים',
                order: 5
            },
            {
                key: 'MyCardDeclaredLogoCode',
                label: 'סוג כרטיס',
                type: 'dropdown',
                required: true,
                placeholder: 'בחירה',
                order: 6
            },
            {
                key: 'MyCardNumber',
                label: 'מספר כרטיס',
                type: 'textbox',
                keyboard: 'number',
                // autoCompleteHtml: https://developers.google.com/web/updates/2015/06/checkout-faster-with-autofill
                autoCompleteHtml: 'cc-number',
                required: true,
                maxLength: 16,
                typingFormat: TypingFormat.IntegerNumbersOnly,
                validators: [
                    {
                        type: 'pattern',
                        value: '([0-9]){8,16}',
                        errorMessage: 'אין מצב שזהו מספר הכרטיס. יש להכניס ספרות בלבד. '
                    }
                ],
                order: 7
            },
            {
                key: 'MyCardExpMonth',
                label: 'תוקף',
                type: 'dropdown',
                required: true,
                placeholder: 'חודש',
                order: 8
            },
            {
                key: 'MyCardExpYear',
                label: '',
                type: 'dropdown',
                required: true,
                placeholder: 'שנה',
                order: 9
            },
            {
                key: 'IdentificationQuestions',
                type: 'label',
                text: 'שאלות אימות',
                order: 10
            },
            {
                key: 'MaidenName',
                label: 'שם המשפחה של אימך לפני הנישואין ',
                type: 'textbox',
                required: true,
                hint: {
                    toggleText: 'מה זה?',
                    text:
                        'שם הנעורים של אמך ישמש כשאלת הזיהוי שלך בפנייה לשירות הלקוחות. זה נראה מצחיק אבל זה הכי בטוח. '
                },
                order: 11,
                validators: [
                    {
                        type: 'pattern',
                        value: '([\u0590-\u05FF-\'" ]+$)',
                        errorMessage: 'המחשב שלנו מבין רק עברית. יש להכניס אותיות בעברית בלבד'
                    },
                    {
                        type: 'maxlength',
                        value: 20,
                        errorMessage: 'יש להכניס שם משפחה באורך של עד 20 תווים'
                    }
                ]
            },
            {
                key: 'GrandmotherName',
                label: 'שמה הפרטי של סבתך מצד האם ',
                type: 'textbox',
                required: true,
                hint: {
                    toggleText: 'מה זה?',
                    text:
                        'שמה הפרטי של סבתך ישמש כשאלת הזיהוי שלך בפנייה לשירות הלקוחות. זה נראה מצחיק אבל זה הכי בטוח. '
                },
                order: 12,
                validators: [
                    {
                        type: 'pattern',
                        value: '([\u0590-\u05FF-\'" ]+$)',
                        errorMessage: 'המחשב שלנו מבין רק עברית. יש להכניס אותיות בעברית בלבד'
                    },
                    {
                        type: 'maxlength',
                        value: 20,
                        errorMessage: 'יש להכניס שם משפחה באורך של עד 20 תווים'
                    }
                ]
            },
            {
                key: 'PublicJobsLabel',
                type: 'label',
                text: 'תפקיד ציבורי או קרבה לכאלה',
                order: 13
            },
            {
                key: 'PublicDutyRelatedInd',
                label: 'האם יש לך תפקיד ציבורי בכיר או קרבה מדרגה ראשונה לבעלי תפקיד כזה? ',
                type: 'switch',
                value: false,
                options: [
                    {
                        text: 'כן',
                        value: true
                    },
                    {
                        text: 'לא',
                        value: false
                    }
                ],
                hint: {
                    toggleText: 'מה זה?',
                    text:
                        'לקחת נשימה עמוקה ולהתחיל לקרוא למה אנחנו מתכוונים: "ראש ממשלה, נשיא מדינה, ראש עיר, שופט, חבר כנסת, חבר ממשלה וקצין צבא בכיר (דרגת אלוף או רב אלוף)  או משטרה בכיר (ניצב או רב ניצב)  או כל ממלא תפקיד כאמור אף אם תוארו שונה  או בעל קרבה מדרגה ראשונה – ילד / הורה / בן זוג"'
                },
                order: 13.1
            },
            {
                key: 'PublicDutyRelatedFirstName',
                label: 'שם פרטי',
                type: 'textbox',
                required: true,
                visible: false,
                order: 13.2,
                validators: [
                    {
                        type: 'pattern',
                        value: '([\u0590-\u05FF-\'" ]+$)',
                        errorMessage: 'עברית שפה יפה. יש להכניס אותיות בעברית בלבד'
                    },
                    {
                        type: 'maxlength',
                        value: 15,
                        errorMessage: 'יש להכניס שם פרטי באורך של עד 15 תווים'
                    }
                ]
            },
            {
                key: 'PublicDutyRelatedLastName',
                label: 'שם משפחה',
                type: 'textbox',
                required: true,
                visible: false,
                order: 13.3,
                validators: [
                    {
                        type: 'pattern',
                        value: '([\u0590-\u05FF-\'" ]+$)',
                        errorMessage: 'עברית שפה יפה. יש להכניס אותיות בעברית בלבד'
                    },
                    {
                        type: 'maxlength',
                        value: 15,
                        errorMessage: 'יש להכניס שם משפחה באורך של עד 15 תווים'
                    }
                ]
            },
            {
                key: 'PublicDutyDescription',
                label: 'פירוט תפקיד',
                type: 'textbox',
                required: true,
                visible: false,
                order: 13.4,
                validators: [
                    {
                        type: 'pattern',
                        value: '([\u0590-\u05FF-\'" ]+$)',
                        errorMessage: 'רק אותיות בעברית במטותא'
                    },
                    {
                        type: 'maxlength',
                        value: 50,
                        errorMessage: 'יש להכניס פירוט תפקיד באורך של עד 50 תווים'
                    }
                ]
            },
            {
                key: 'AnotherPublicDutyLabel',
                type: 'label',
                text: 'האם הנך או קרובי משפחתך מדרגה ראשונה עוסקים בתחומים הבאים?(ניתן לסמן יותר מאחד):',
                order: 13.5,
                visible: false,
            },
            {
                key: 'IndustryAndSecurityInd', //AnotherPublicDuty_IndustryAndSecurityInd
                type: 'checkbox',
                text: 'תעשיות ועסקאות ביטחוניות',
                value: false,
                order: 13.51,
                visible: false
            },
            {
                key: 'OilAndGazInd', //AnotherPublicDuty_OilAndGazInd
                type: 'checkbox',
                text: 'כרייה של גז ונפט',
                value: false,
                order: 13.52,
                visible: false
            },
            {
                key: 'EnergyNaturalResourcesInd', //AnotherPublicDuty_EnergyNaturalResourcesInd
                type: 'checkbox',
                text: 'אנרגיה או משאבים טבעיים אחרים',
                value: false,
                order: 13.53,
                visible: false
            },
            {
                key: 'RealEstateConstructionInd', //AnotherPublicDuty_RealEstateConstructionInd
                type: 'checkbox',
                text: 'ענף בנייה ונדל"ן',
                value: false,
                order: 13.54,
                visible: false
            },
            {
                key: 'ServToGovermentPublicInd', //AnotherPublicDuty_ServToGovermentPublicInd
                type: 'checkbox',
                text: 'עסקים שמספקים שירות או מוצרים למוסדות ציבוריים וממשלתיים',
                value: false,
                order: 13.55,
                visible: false
            },
            {
                key: 'AssociasionAndSportInd', //AnotherPublicDuty_AssociasionAndSportInd
                type: 'checkbox',
                text: 'אגודות וענפי ספורט',
                value: false,
                order: 13.56,
                visible: false
            },
            {
                key: 'NoAnotherPublicDutyInd', //AnotherPublicDuty_NoInd
                type: 'checkbox',
                text: 'אף אחד מהם',
                value: false,
                order: 13.57,
                visible: false
            },
            {
                key: 'AnotherCitizenshipInd',
                label: 'האם יש לך אזרחות ו/או תושבות מס נוספת (שאינה ישראלית)',
                type: 'radioGroup',
                options: [
                    {
                        label: 'נכון',
                        value: 'true'
                    },
                    {
                        label: 'לא נכון',
                        value: 'false'
                    }
                ],
                required: true,
                order: 14
            },
            {
                key: 'Bank411RefusalServicesInd',
                label: 'האם סורבת לקבל שירותים בתאגיד בנקאי כלשהו מסיבות הקשורות באיסור הלבנת הון ומימון טרור?',
                type: 'switch',
                value: false,
                options: [
                    {
                        text: 'כן',
                        value: true
                    },
                    {
                        text: 'לא',
                        value: false
                    }
                ],
                order: 15
            },
            {
                key: 'DreamCardLLabel',
                text: 'מועדון DreamCard',
                type: 'label',
                order: 16
            },
            {
                key: 'MarriageDate',
                label: 'תאריך יום נישואין',
                type: 'mask',
                required: false,
                keyboard: 'number',
                placeholder: 'DD/MM/YYYY',
                order: 16.1,
                validators: [
                    {
                        type: 'isFutureDate',
                        errorMessage: 'כנראה שלא התחתנתם בעתיד...אנא הזינו תאריך יום נישואין תקין.'
                    },
                    {
                        type: 'invalidDate',
                        errorMessage: 'אין מצב שהתחתנתם בתאריך הזה. יש להכניס תאריך יום נישואין תקין'
                    }
                ]
            },
            {
                key: 'ArrayOfFoxChildDetails',
                type: 'dreamCardChildren',
                required: false,
                order: 16.2
            },
            {
                key: 'KranotMembershipPaymentAllowInd',
                type: 'checkbox',
                customTextTitle: 'אישור תשלום דמי חבר',
                customText:
                    'אני מאשר לחייב את כרטיס אשראי max קרנות השוטרים שיונפק עפ"י בקשתי בסכומים ובמועדים כפי שתעביר לכם מדי פעם בפעם חברת קרנות השוטרים בע"מ בגין עלות חברותי בקרנות השוטרים: בסך 39 ₪ כולל מע"מ (נכון למועד זה). ידוע לי כי סכומים אלה יעודכנו מעת לעת על פי החלטות המוסדות המוסמכים של  קרנות השוטרים.',
                value: false,
                required: true,
                order: 17
            }
        ]
    },
    {
        key: 'card-details',
        header: ' שלב 4 ואחרון. אפשר כבר לראות כרטיס באופק',
        id: 4,
        label: 'הכרטיס החדש',
        type: 'group',
        controls: [
            {
                key: 'EnglishNameLabel',
                type: 'label',
                text: 'שם באנגלית עבור ההבלטה על הכרטיס',
                order: 0
            },
            {
                key: 'FirstNameEng',
                label: 'שם פרטי באנגלית (כמו בדרכון) ',
                type: 'textbox',
                required: true,
                validators: [
                    {
                        type: 'pattern',
                        value: '([a-zA-Z \'"-]+$)',
                        errorMessage: 'כל האותיות צריכות להיות באנגלית'
                    },
                    {
                        type: 'maxlength',
                        value: 10,
                        errorMessage: 'יש להכניס שם באורך של עד 10 תווים'
                    }
                ],
                order: 1
            },
            {
                key: 'LastNameEng',
                label: 'שם המשפחה באנגלית (כמו בדרכון) ',
                type: 'textbox',
                required: true,
                validators: [
                    {
                        type: 'pattern',
                        value: '([a-zA-Z \'"-]+$)',
                        errorMessage: 'כל האותיות צריכות להיות באנגלית'
                    },
                    {
                        type: 'maxlength',
                        value: 16,
                        errorMessage: 'יש להכניס שם משפחה באנגלית באורך של עד 16 תווים'
                    }
                ],
                order: 2
            },
            {
                key: 'CardCreditLimit',
                type: 'textbox',
                label: 'מסגרת הכרטיס',
                readonly: true,
                hint: {
                    toggleText: 'מה זה?',
                    text: 'במידה ומסגרת האשראי אינה תואמת את מסגרת האשראי בכרטיסך הבנקאי, ניתן לפנות אלינו ב-sms למספר: 055-7000154, לאחר הפעלת הכרטיס, על מנת לבחון את הגדלת המסגרת'
                },
                order: 3
            },
            {
                key: 'CardUseLabel',
                type: 'label',
                text: 'שימוש בכרטיס',
                order: 4
            },
            {
                key: 'ForUseByCode',
                label: 'למה ישמש הכרטיס?',
                type: 'dropdown',
                required: true,
                placeholder: 'בחירה',
                order: 5
            },
            {
                key: 'AveragePurchaseCode',
                label: 'סכום רכישות ומשיכות מזומן ממוצע צפוי בכרטיס ',
                type: 'dropdown',
                required: true,
                placeholder: 'בחירה',
                hint: {
                    toggleText: 'מה זה?',
                    text: 'כולל עסקות ומשיכות מזומן'
                },
                order: 6
            },
            {
                key: 'FixedChargeSectionTitle',
                type: 'label',
                text: 'חיוב קבוע לכרטיס',
                hint: {
                    toggleText: 'מה זה?',
                    text: 'שירות שמאפשר לך לקבוע כמה לשלם כל חודש. היתרה תעבור לחודש הבא בתוספת ריבית'
                },
                order: 7
            },
            //{
            //    key: 'CardCreditLimit',
            //    type: 'label',
            //    text: 'מסגרת הכרטיס: {cardCreditLimit} ₪',
            //    //visible: false,
            //    hint: {
            //        toggleText: 'מה זה?',
            //        text: 'במידה ומסגרת האשראי אינה תואמת את מסגרת האשראי בכרטיסך הבנקאי, ניתן לפנות אלינו ב-sms למספר: 055-7000154, לאחר הפעלת הכרטיס, על מנת לבחון את הגדלת המסגרת'
            //    },
            //    order: 6.1
            //},
            {
                key: 'FixedChargeInd',//FixPayApproveInd
                label: 'האם להגדיר חיוב קבוע בכרטיס?',
                type: 'radioGroup',
                options: [
                    {
                        label: 'מעוניין',
                        value: 1
                    },
                    {
                        label: 'לא מעוניין',
                        value: 0
                    }
                ],
                required: true,
                order: 7.05
            },
            {
                key: 'ShowPreviousChargesInd',
                label: 'האם תרצה לצפות בהתחייבויות קודמות שיש בכרטיסי max הנוספים שלך?',
                type: 'radioGroup',
                options: [
                    {
                        label: 'כן',
                        value: true
                    },
                    {
                        label: 'לא',
                        value: false
                    }
                ],
                visible: false,
                required: true,
                order: 7.1
            },
            {
                key: 'ShowPreviousChargesLabel',
                type: 'label',
                //text: 'לא נוכל להמשיך בתהליך ללא צפייה בהתחייבויות קודמות בכרטיסך',
                //optionalText: 'אנו שמחים שבחרת לצפות בהתחייבות הקודמות בכרטיסך. תוכל לקבוע חיוב קבוע באתר max לאחר הפעלת הכרטיס.<br><b class="bold"> אנחנו מזמינים אותך להשלים את תהליך ההצטרפות</b>',
                optionalText: 'ניתן לצפות בהתחייבויות הקודמות שלך באתר שלנו לאחר סיום ההנפקה ולקבוע חיוב קבוע על הכרטיס לאחר הפעלתו. כרגע נמשיך בתהליך ההנפקה ללא הגדרת חיוב קבוע.',
                visible: false,
                order: 7.11
            },
            {
                key: 'FixedChargeAmount',//FixPayment
                label: 'קביעת סכום החיוב הקבוע החודשי',
                type: 'textbox',
                keyboard: 'number',
                hint: {
                    toggleText: 'מה זה?',
                    text: 'סכום החיוב החודשי אשר נקבע על ידך, הינו קבוע וישתנה רק אם תבחר/י לשנות אותו. הסכום ירד מתוך סך כל החיובים לאותו חודש (הכולל עסקאות, הלוואות, ריביות ועמלות שונות). יתרת הסכום תועבר לחודש הבא (כסל מצטבר) בתוספת ריבית בהתאם לריבית שנקבעה בכרטיס שלך. בכרטיס זה ניתן להגדיר חיוב קבוע של 200 ש"ח מינימום או 1% מגובה המסגרת (הגבוה מבינהם) ובהפרשים של 10 ש"ח לדוגמא: 210 ש"ח, 220 ש"ח וכן הלאה, עד לגובה המסגרת בכרטיס שלך'
                },
                required: true,
                order: 7.2,
                visible: false,
                maxLength: 8,
                typingFormat: TypingFormat.IntegerNumbersOnly,
                warningText: 'בחרת לקבוע חיוב קבוע בגובה המסגרת. לכן, בכל חודש יחויב כרטיסך לפי סכום השימוש בכרטיס ועד לגובה המסגרת.',
                validators: [
                    {
                        type: 'pattern',
                        value: '^[0-9]{0,8}',
                        errorMessage: 'יש להכניס ספרות בלבד.'
                    },
                    {
                        type: 'max',
                        value: 5000,
                        errorMessage: 'גובה החיוב הקבוע האפשרי הינו בין {minimumFix} ₪ ל- {cardCreditLimit} ₪'
                    },
                    {
                        type: 'min',
                        value: 500,
                        errorMessage: 'גובה החיוב הקבוע האפשרי הינו בין {minimumFix} ₪ ל- {cardCreditLimit} ₪'
                    }
                ]
            },
            {
                key: 'FixedChargeAmountAdviceLabel',
                type: 'label',
              //  text: 'המלצת המערכת: XXXXXX ₪ <br> החל מ-200 ₪ ועד לגובה מסגרת הכרטיס',
                //optionalText: 'ההצעה שלנו: {fixedChargeAmountAdvice} ₪ <br> החל מ-200 ₪ ועד לגובה מסגרת הכרטיס',
                optionalText: 'החל מ-200 ₪ ועד לגובה מסגרת הכרטיס',
                visible: false,
                order: 7.21
            },
            {
                key: 'InterestsSummary',
                label: 'ריבית הסל המצטבר',
                type: 'ulList',
                visible: false,
                options: [
                    {
                        label: '',
                        value: '{nominalInterest}% ריבית נומינלית',
                        icon: '/assets/images/join-form/flag.svg'
                    },
                    {
                        label: '',
                        value: '{effectiveInterest}% ריבית אפקטיבית',
                        icon: '/assets/images/join-form/flag.svg'
                    }
                ],
                required: true,
                order: 7.3
            },
            {
                key: 'FixedChargeReasonCode',//FixPaymentReasonCode
                label: 'סיבה לחיוב קבוע',
                type: 'radioGroupExtended',
                value: 16,
                options: [
                    {
                        label: 'כיסוי התחייבויות',
                        value: 12
                    },
                    {
                        label: 'חופשה או אירוע',
                        value: 13
                    },
                    {
                        label: 'רכישת רכב/מוצר/שירות',
                        value: 14
                    },
                    {
                        label: 'טיפול רפואי',
                        value: 15
                    },
                    {
                        label: 'שליטה בהוצאות',
                        value: 16
                    },
                    {
                        label: 'אחר',
                        value: 17
                    }
                ],
                visible: false,
                required: true,
                order: 7.4
            },
            {
                key: 'FixedChargeAsteriskImportant',
                text: 'חשוב לדעת',
                optionalText: '*סכום החיוב החודשי שבחרת יתעדכן תוך יום עסקים, אם יום החיוב הוא יום לאחר מועד השינוי ייתכן שהעדכון יחול רק בחודש הבא. *יתרת הסכום, שלא תחוייב, תצורף לסל עסקות מצטבר שיישא ריבית בהתאם לריבית שנקבעה בכרטיסך. *בכפוף לתנאי ההצטרפות ולאישורה של max.  קביעת/שינוי/ביטול סכום החיוב אפשרית עד יום עסקים אחד לפני מועד החיוב החודשי, עד השעה 17:00 בערוצי ההתקשרות הבאים: אתר, אפליקציה, מענה קולי ומוקד שירות הלקוחות. *ניתן לקבוע החזר חודשי מינימאלי בסך 200 ₪ או 1% ממסגרת האשראי, הגבוה מביניהם. *בכרטיסים שאינם מסוג מולטי ניתן לקבוע החזר חודשי מינימאלי בסך 500 ₪ או 1% ממסגרת האשראי, הגבוה מביניהם. *אם גובה החיוב הקבוע שנקבע בכרטיס שווה לגובה מסגרת הכרטיס, הכרטיס יחויב בכל סכום העסקאות שבוצעו באותו החודש, לרבות סל העסקות המצטבר. *אם הכרטיס חורג ממסגרת האשראי שהוקצתה לו, החיוב החודשי יהיה בגובה החריגה מהמסגרת. *פירוט אודות הלוואות קיימות בכרטיסך ניתן למצוא בדפי הפירוט החודשיים. *המלווה: מקס, בכפוף לאישור ותנאי החברה *אי עמידה בפירעון ההלוואה עלול לגרור חיוב בריבית פיגורים והליכי הוצאה לפועל.',
                type: 'label',
                visible: false,
                order: 7.5
            },
            {
                key: 'TransferFromOtherCardsLabel',
                type: 'label',
                text: 'העברת הוראות קבע מכרטיסים אחרים',
                order: 8
            },
            {
                key: 'StandingOrderInd',
                label: 'מעבירים את הו"ק לכרטיס ונהנים מפינוקים והטבות',
                type: 'radioGroup',
                options: [
                    {
                        label: 'כן',
                        value: 1
                    },
                    {
                        label: 'לא',
                        value: 0
                    }
                ],
                hint: {
                    toggleText: 'מה זה?',
                    text:
                        'אם יש לך הוראות קבע בכרטיסים אחרים, אנחנו נבקש מבתי העסק להעביר אותן עבורך לכרטיס החדש שמונפק כרגע.  מומלץ לעקוב אחרי החיובים'
                },
                required: true,
                order: 9
            },
            {
                key: 'StandingOrderCardNumber1',
                label: 'כרטיס מספר 1',
                type: 'textbox',
                keyboard: 'number',
                maxLength: 16,
                typingFormat: TypingFormat.IntegerNumbersOnly,
                validators: [
                    {
                        type: 'pattern',
                        value: '([0-9]){14,16}',
                        errorMessage: 'המספר לא תקין. אפשר לנסות שוב.'
                    },
                    {
                        type: 'notEqual',
                        targetKey: 'StandingOrderCardNumber2',
                        errorMessage: 'נראה שכבר נתת לנו את הכרטיס הזה, אפשר אחד אחר?'
                    },
                    {
                        type: 'notEqual',
                        targetKey: 'StandingOrderCardNumber3',
                        errorMessage: 'נראה שכבר נתת לנו את הכרטיס הזה, אפשר אחד אחר?'
                    }
                ],
                required: true,
                order: 10
            },
            {
                key: 'StandingOrderCardNumber2',
                label: 'כרטיס מספר 2 (רשות)',
                type: 'textbox',
                keyboard: 'number',
                required: false,
                maxLength: 16,
                typingFormat: TypingFormat.IntegerNumbersOnly,
                validators: [
                    {
                        type: 'pattern',
                        value: '([0-9]){14,16}',
                        errorMessage: 'המספר לא תקין. אפשר לנסות שוב.'
                    },
                    {
                        type: 'notEqual',
                        targetKey: 'StandingOrderCardNumber1',
                        errorMessage: 'נראה שכבר נתת לנו את הכרטיס הזה, אפשר אחד אחר?'
                    },
                    {
                        type: 'notEqual',
                        targetKey: 'StandingOrderCardNumber3',
                        errorMessage: 'נראה שכבר נתת לנו את הכרטיס הזה, אפשר אחד אחר?'
                    }
                ],
                order: 11
            },
            {
                key: 'StandingOrderCardNumber3',
                label: 'כרטיס מספר 3 (רשות)',
                type: 'textbox',
                keyboard: 'number',
                required: false,
                maxLength: 16,
                typingFormat: TypingFormat.IntegerNumbersOnly,
                validators: [
                    {
                        type: 'pattern',
                        value: '([0-9]){14,16}',
                        errorMessage: 'המספר לא תקין. אפשר לנסות שוב.'
                    },
                    {
                        type: 'notEqual',
                        targetKey: 'StandingOrderCardNumber1',
                        errorMessage: 'נראה שכבר נתת לנו את הכרטיס הזה, אפשר אחד אחר?'
                    },
                    {
                        type: 'notEqual',
                        targetKey: 'StandingOrderCardNumber2',
                        errorMessage: 'נראה שכבר נתת לנו את הכרטיס הזה, אפשר אחד אחר?'
                    }
                ],
                order: 12
            },
            {
                key: 'GetInformationLabel',
                text: 'קבלת המידע באמצעות מענה קולי ומייל',
                type: 'label',
                order: 13
            },
            {
                key: 'IvrServiceCode',
                label: 'מענה קולי (מומלץ)',
                type: 'dropdown',
                //required: true,
                //value: 2,
                placeholder: '',
                hint: {
                    toggleText: 'מה זה?',
                    text:
                        'באמצעות המענה הקולי ניתן לבצע פעולות ולקבל מידע 24 שעות ביממה,כגון: חיובי חשבונך, הלוואות, המרת נקודות למועדוני טיסה וכד\' (נועד לאיסוף מידע בלבד. ) המשתמש לא יוכל לדעת דרך התא הקולי פרטים לגבי ההלוואה.'
                },
                order: 14
            },
            {
                key: 'ReceivingDetailPagesLabel',
                text: 'דפי פירוט ישירות למייל',
                type: 'label',
                optionalText: '',
                order: 15
            },
            {
                key: 'DigitalLettersInd',
                type: 'checkbox',
                customText: 'אני רוצה לקבל את פירוט החיובים בדואר ולא במייל',
                value: false,
                order: 18,
                customClass: 'optional-checkbox'
            },
            {
                key: 'AllowOfferLoanByRepInd',
                type: 'checkbox',
                customText: 'אני רוצה שנציג מטעמכם יחזור אלי עם <br> הצעת הלוואה למצטרפים חדשים',
                value: false,
                order: 19,
                required: false,
                customClass: 'optional-checkbox'
            },
            {
                key: 'InsuranceHeader',
                text: 'חדשות טובות! לחברת Max יש סוכנות ביטוח משלה, בכדי שהסוכנות תוכל להציע לך ביטוח בהתאמה אישית ולהציג את המידע הביטוחי שלך באפליקציית ובאתר max, אנחנו צריכים לקבל את הסכמתך מראש',
                type: 'label',
                optionalText: 'אני מאשר/ת למקס איט פיננסים בע"מ ("מקס") וכן למקס סוכנות לביטוח (2020) בע"מ, חברה בשליטתה של מקס ("הסוכנות") ומסמיך אותן להחליף ולהעביר ביניהן את כל המידע אודותיי שמסרתי ו/או שנצבר אצלן, לרבות בקשר עם שימושיי במוצריהן ושירותיהן, ולעשות בו שימוש כמפורט להלן, בהתאם ובכפוף לתנאי רישיון הסוכנות. מקס, הסוכנות, החברה המבטחת ו/או מי מטעמן ישתמשו במידע לצורכי מחקרים וניתוחים שונים, למטרת מתן שירות וכן למטרת שיווק ודיוור ישיר ושירותי דיוור ישיר (אך רק בתוך הקבוצה) וכדי להציע לי הצעות ערך, כולל מוצרים ושירותים שונים שיוצעו ע"י מקס ו/או הסוכנות מפעם לפעם, כגון מוצרי ביטוח, חיסכון ומוצרים פיננסיים אחרים שעשוי להיות לי עניין בהם ו/או שיותאמו אליי אישית על בסיס נתוניי האישיים והמידע האמור, לרבות הודעות ופרסומים באמצעי קשר אלקטרוניים ואוטומטיים. אם לא אהיה מעוניין/ת בקבלת הודעות ופרסומים כאמור באחד או ביותר מהאמצעים הנ"ל, או שארצה לחזור בי מהסכמתי להעברת המידע, אהיה רשאי/ת להודיע על כך למקס 03-6178888 ו/או לסוכנות 6411* בכל עת.',
                order: 20
            },
            {
                key: 'InsuranceInd',
                type: 'checkbox',
                customTextTitle: 'אני מאשר/ת',
                //value: false,
                order: 21
            },
            {
                key: 'AddDocsLabel',
                text: 'צירוף מסמכים',
                label: 'צירוף מסמכים',
                optionalText: '',
                type: 'label',
                hint: {
                    toggleText: 'מה זה?',
                    text: 'כאן צריך להעלות מסמך שייכות למועדון'
                },
                order: 22
            },
            {
                key: 'IDType',
                label: 'סוג תעודה',
                type: 'radioGroup',
                options: [
                    {
                        label: 'רגילה',
                        value: 1
                    },
                    {
                        label: 'ביומטרית',
                        value: 2
                    }
                ],
                required: true,
                order: 22.1
            },
            {
                key: 'IdIssueDate',
                label: 'תאריך הנפקת התעודה',
                type: 'mask',
                required: true,
                keyboard: 'number',
                placeholder: 'DD/MM/YYYY',
                order: 22.2,
                validators: [
                    {
                        type: 'invalidDate',
                        errorMessage: 'התאריך לא תקין, וכדי שלא יהיה ספק:DD/MM/YYYY'
                    },
                    {
                        type: 'isFutureDate',
                        errorMessage: 'כנראה שהתעודה לא הונפקה בעתיד... אנא הזינו תאריך הנפקה תקין.'
                    },
                ]
            },
            {
                key: 'IdValidationDate',
                label: 'תוקף התעודה',
                type: 'mask',
                required: true,
                keyboard: 'number',
                placeholder: 'DD/MM/YYYY',
                order: 22.3,
                validators: [
                    {
                        type: 'isFutureDatePlusXDays',
                        errorMessage: 'אין מצב שהתוקף נכון, יש להזין תוקף עתידי תקין.'
                    },
                    {
                        type: 'invalidDate',
                        errorMessage: 'התאריך לא תקין, וכדי שלא יהיה ספק:DD/MM/YYYY'
                    }
                ]
            },
            {
                key: 'IdValidationDateNotRequired',
                label: 'תוקף התעודה',
                type: 'mask',
                required: false,
                keyboard: 'number',
                placeholder: 'DD/MM/YYYY',
                order: 22.3,
                validators: [
                    {
                        type: 'isFutureDatePlusXDays',
                        errorMessage: 'אין מצב שהתוקף נכון, יש להזין תוקף עתידי תקין.'
                    },
                    {
                        type: 'invalidDate',
                        errorMessage: 'התאריך לא תקין, וכדי שלא יהיה ספק:DD/MM/YYYY'
                    }
                ]
            },
            {
                key: 'AttachedFile',
                type: 'uploadPhoto',
                photoType: 'ID',
                title: 'צילום העלאת / העלאת קובץ',
                text: 'גודל מקסימלי - 5MB, מסוג JPG / GIF / PNG / PDF',
                required: true,
                order: 23,
                validators: []
            },
            {
                key: 'AttachedFileBackSide',
                type: 'uploadPhoto',
                photoType: 'ID',
                title: 'צילום העלאת / העלאת קובץ',
                text: 'גודל מקסימלי - 5MB, מסוג JPG / GIF / PNG / PDF',
                required: true,
                order: 23.1,
                validators: []
            },
            {
                key: 'MoreWaysAddDocs',
                type: 'checkbox',
                text: ' תראו לי דרכים אחרות לשלוח לכם את הקבצים הנדרשים.',
                value: false,
                order: 24
            },
            {
                key: 'MMS',
                type: 'itext',
                icon: 'chat',
                text: 'הודעת MMS למספר סלולארי 054-4567890',
                order: 25
            },
            {
                key: 'MAIL',
                type: 'itext',
                icon: 'envelope',
                text: 'אימייל - id@leumi-card.co.il',
                order: 26
            },
            {
                key: 'Bonus',
                text: 'מסלול ההטבות שלך בכרטיס החדש',
                optionalText:
                    'תכנית ההטבות והפינוקים מעניקה הטבות במגוון תחומים בארץ ובחו"ל: קולנוע, הופעות חיות, הצגות, אטרקציות, קניות באינטרנט, מסעדות ובתי קפה.<br> * <a href="https://www.max.co.il/he-il/Benefits/BenefitsPlus/Documents/PinukPlus.pdf" target = "_blank" > כפוף לתקנון הפינוקים</a> ואתר ההטבות של max',
                type: 'label',
                order: 27
            },
            {
                key: 'Discloser',
                text: 'צפייה במידע ברמת חשבון',
                optionalText:
                    'במידה ויש מספר שותפים בחשבון הבנק אותו מחייב כרטיס זה, פירוט החיובים של כל הכרטיסים בחשבון יוצג לכל השותפים. ניתן לשנות זאת בכל עת בפנייה למוקד שרות הלקוחות',
                type: 'label',
                order: 27
            },
            {
                key: 'INeedItGroup',
                text: 'חברות בקבוצת "כזה אני רוצה! I need it"',
                optionalText: 'הזמנת הכרטיס מותנית בחברות בקבוצת "כזה אני רוצה! I Need It" בפייסבוק.<br>לmax עומדת הזכות לבדיקה תקופתית ובמידת הצורך לביטול ההטבות בכרטיס עקב אי חברות בקבוצה.',
                type: 'label',
                order: 27.1
            },
            {
                key: 'TermsCryptoCardSuperK',
                text: 'תנאי שימוש בכרטיס',
                optionalText: 'התנאים שיחולו על הכרטיס מפורטים <a href="https://www.max.co.il/api/umbraco/getImage?imageName=takanon-maxback-crypto.pdf" target="_blank"> בתקנון כרטיס maxBack Crypto</a> <a href="https://www.bitsofgold.co.il/terms_max" target="_blank"> ובתנאי השימוש של חברת Bits of Gold</a>',
                type: 'label',
                order: 27.2
            },
            {
                key: 'Approval',
                type: 'checkbox',
                optionalText:
                    'קראתי ואישרתי את תוכן ו<a href ="#" target="_blank">תנאי ההסכם</a>, והבנתי שחובה עליי לפעול לפיו',
                value: false,
                order: 28
            }
        ]
    },
    {
        key: 'confirmation',
        header: 'אישור על הכפתור למטה ועשינו את זה',
        id: 5,
        type: 'group',
        label: 'אישור פרטים',
        controls: [
            {
                key: 'GetCardLabel',
                type: 'label',
                text: 'הנפקת כרטיס max בהצדעה',
                optionalText: 'אישור פרטים',
                order: 0
            },
            {
                key: 'summary',
                type: 'summaryBox',
                options: [
                    {
                        label: 'פרטים אישיים',
                        options: [
                            {
                                key: 'Name',
                                label: 'שם:',
                                value: 'ישראל ישראל'
                            },
                            {
                                key: 'Id',
                                label: 'ת.ז.:',
                                value: '333333333'
                            },
                            {
                                key: 'Address',
                                label: 'כתובת:',
                                value: 'החרוב 3, תל אביב'
                            },
                            {
                                key: 'Cellular',
                                label: 'טלפון:',
                                value: '0521234567'
                            }
                        ]
                    },
                    {
                        label: 'פרטי כרטיס אשראי',
                        options: [
                            {
                                label: 'סוג כרטיס:',
                                value: 'max בהצדעה זהב'
                            },
                            {
                                label: 'תאריך חיוב:',
                                value: '2 לחודש'
                            },
                            {
                                label: 'מסגרת הכרטיס:',
                                value: '15000'
                            }
                        ]
                    },
                    {
                        label: 'חשבון בבנק לחיוב',
                        options: [
                            {
                                label: 'בנק:',
                                value: 'בנק הפועלים'
                            },
                            {
                                label: 'מספר סניף:',
                                value: '2607'
                            },
                            {
                                label: 'מספר חשבון:',
                                value: '12345'
                            }
                        ]
                    }
                ]
            }
        ]
    }
];