// tslint:disable:max-line-length
import { AppleSignInService } from '../../../../shared/cardholders-core/services/apple-sign-in.service';
import { FingerPrintService } from '../../../../shared/cardholders-core/services/fingerPrint.service';
import { Component, OnInit, Inject, HostListener, ElementRef, OnDestroy, Input, Optional } from '@angular/core';
import { UiHelperService } from '../../../../shared/cardholders-core/services/ui-helper.service';
import { LoggerService } from '../../../../shared/cardholders-core/services/logger.service';
import { Base64Service } from '../../../../shared/cardholders-core/services/base64.service';
import { AuthService } from '../../../../shared/cardholders-core/services/auth.service';
import { GtmService } from '../../../../shared/cardholders-core/services/gtm.service';
import { AppConfigsService } from '../../../../config/app-configs.service';
import { MainLayoutService } from '../../services/main-layout.service';
import { LoginService } from '../../../login/login.service';
import { Router, ActivatedRoute } from '@angular/router';
import { isNullOrUndefined } from 'core-max-lib';
import { DetectorService } from '../../../../shared/cardholders-core/services/detector.service';
import { Subscription } from 'rxjs';
import { isAppleDevice } from '../../../../shared/services/is-apple-device';
import { TrusteerSnippetsService } from '../../../../shared/cardholders-core/services/trusteer-snippets.service';
import { OpenBankingService } from '../../../open-banking/open-banking.service';
import { a11yFix } from '../../../../shared/utils/a11y.utils';
import { Fido2Service } from '../../../../shared/cardholders-core/services/fido2.service';
import { uiIsMobile } from '../../../../shared/services/isMobile';
import { isIos } from '../../../../shared/services/isIos';

declare const PublicKeyCredential: any;
declare const navigator: any;
declare const AppleID: any;
interface FingerPrintStates {
    loader: boolean;
    message: any;
    showMessage: boolean;
    show: boolean;
    exist: boolean;
    preRemove?: boolean;
    enableLog: boolean;
}
@Component({
    selector: 'app-user-box',
    templateUrl: './user-box.component.html',
    styleUrls: ['./user-box.component.scss']
})
export class UserBoxComponent implements OnInit, OnDestroy {
    @Input() minimal: boolean;
    @Input()
    isMobileLandscape: boolean;
    showUserTooltip = false;
    isUserBoxOpen = false;
    showUserName: boolean;
    businessRoot: string;
    isNavOpen: boolean;
    isMobile: boolean;
    infoRoot: string;
    baseUiRoot: string;
    loaded = false;
    userbox: any;
    unreadLetters: number;
    @Input() isEve8: boolean;
    @Input() isEve10: boolean;

    fingerPrintStates: FingerPrintStates = {
        loader: false,
        message: null,
        showMessage: false,
        show: false,
        exist: false,
        preRemove: false,
        enableLog: false
    };
    appleSignInStates = {
        loader: false,
        message: null,
        showMessage: false,
        show: false,
        exist: false,
        enableLog: false,
        showPopup: false
    };
    APPLE_SIGN_IN_STORAGE_KEY = 'appleSignIn';
    originUrl;
    isGetChallenge: boolean;
    isUserById: Boolean;
    touchIDAggrement: string;
    isAdmin: Boolean;
    isHeaderTransparent: boolean;
    private appleSignInChallenge: string;
    private subs: Subscription[] = [];
    fido2Exists: boolean = false;
    fido2Loader: boolean = false;
    fido2Info: string;
    showFido2 = uiIsMobile();

    constructor(
        private eRef: ElementRef,
        private uiHelper: UiHelperService,
        private appConfig: AppConfigsService,
        @Optional() @Inject('serverUrl') private serverUrl: string,
        private authService: AuthService,
        private mainLayoutService: MainLayoutService,
        private gtmService: GtmService,
        private router: Router,
        private loginSvc: LoginService,
        private fingerPrintService: FingerPrintService,
        private base64Service: Base64Service,
        private route: ActivatedRoute,
        public logger: LoggerService,
        private detectorService: DetectorService,
        private appleSignInService: AppleSignInService,
        private trusteerSnippetsService: TrusteerSnippetsService,
        private openBankingService: OpenBankingService,
        private fido2Srv: Fido2Service
    ) {
        this.originUrl = this.uiHelper.isBrowser ? window.location.origin : this.serverUrl;
        this.businessRoot = this.appConfig.appConfigs.businessRoot;
        this.infoRoot = this.appConfig.appConfigs.infoRoot;
        this.baseUiRoot = this.appConfig.appConfigs.baseUiRoot;
        this.userbox = {};
        this.isMobile = this.uiHelper.IsMobile(768) || this.isMobileLandscape;
        this.subs.push(this.mainLayoutService.unReadMessage.subscribe(x => (this.unreadLetters = x)));
        this.subs.push(this.mainLayoutService.headerTransparent.subscribe(val => (this.isHeaderTransparent = val)));
    }

    onSearchClick(): void {
        this.mainLayoutService.toggleSearchEvent.emit();
    }

    @HostListener('document:click', ['$event'])
    clickOut(event: Event): void {
        if (
            /* Check Click-outside Apple Iframe Desktop+Mobile */
            // !this.uiHelper.IsMobile(768) &&
            // !this.isMobileLandscape &&
            this.mainLayoutService.isUserBoxOpen &&
            !this.eRef.nativeElement.contains(event.target)
        ) {
            if (!this.appleSignInStates.showPopup) {
                this.mainLayoutService.changeUserBoxMode(false);
            }
        }
    }

    async ngOnInit(): Promise<any> {

        const dialogSelectors = {
            dialog: "modal-container.modal",
            closeBtn: "button.close",
            heading: ".title.max-title",
        };
        //mobile
        a11yFix()?.dialog(".personal-area>a.user-details", { selectors: { ...dialogSelectors, trigger: '.personal-area>a.user-details' } });
        // //desktop
        a11yFix()?.dialog("a.go-to-personal-area", { selectors: { ...dialogSelectors, trigger: 'a.go-to-personal-area' } });

        a11yFix()?.form(".tab-pane.active", {
            selectors: {
                form: "app-otp-login-form form, app-user-login-form form",
                submitButton: "#send-code",
                invalidField: ".form-group input",
                inputField: ".form-group:has(.error-msg) input",
                requiredField: "input",
                errorMsg: ".error-msg"
            }
        });

        // setTimeout(() => {}, 2000);
        this.touchIDAggrement = `${this.appConfig.appConfigs.baseUiRoot}/general/pages/touch-id`;
        this.isUserById = this.appConfig.appConfigs.FLAG_ISUserById;
        this.isAdmin = this.authService.isAdmin();
        this.trusteerSnippetsService.loadScript();
        this.subs.push(
            this.mainLayoutService.isNavOpen.subscribe(isNavOpen => {
                this.isNavOpen = isNavOpen;
                if (isNavOpen && this.isUserBoxOpen) {
                    this.toggleUserBox();
                }
            })
        );
        this.subs.push(
            this.mainLayoutService.isUserBoxOpen.subscribe(isUserBoxOpen => {
                this.insideToogleUserBox(isUserBoxOpen);
                if (this.isUserBoxOpen) {
                    this.subs.push(this.fido2Srv.fido2Info.subscribe(res => { this.fido2Info = res }));
                    this.subs.push(this.fido2Srv.fido2Loading.subscribe(res => { this.fido2Loader = res }));
                    this.appleGetChallengeAfterBoxOpen();
                    this.fido2Exists = this.fido2Srv.fido2Exists;
                    if(this.isAuthenticated() && !this.fido2Srv.fido2Checked){
                        this.checkFidoCredentials();
                    }
                }
            })
        );
        if (this.uiHelper.isBrowser && !window.location.href.includes(`${this.baseUiRoot}/login`)) {
            this.loaded = true;

            if (this.isAuthenticated() && !this.openBankingService.isOpenBankingLoginMode()) {
                this.trusteerSnippetsService.loadCallbackScripts(true);
                this.doWhenLoggedIn();
            } else {
                this.trusteerSnippetsService.loadCallbackScripts(false);
            }
        } else {
            this.trusteerSnippetsService.loadCallbackScripts(false);
        }
        this.authService.onAuthenticationChangeSubject.subscribe(isAuth => {
            if (isAuth && !this.openBankingService.isOpenBankingLoginMode()) {
                this.trusteerSnippetsService.loadCallbackScripts(true);
                this.doWhenLoggedIn();
            } else {
                this.trusteerSnippetsService.loadCallbackScripts(false);
            }
        });

        if (this.uiHelper.isBrowser) {
            this.fingerPrintStates.enableLog = this.route.snapshot.queryParams.log;

            if (this.fingerPrintStates.enableLog) {
                this.logger.error(
                    JSON.parse(
                        JSON.stringify({
                            enabledWithoutQp: this.appConfig.appConfigs.FLAG_ISFingerPrintEnabledWithoutQp,
                            Enabled: this.appConfig.appConfigs.FLAG_ISFingerPrintEnabled,
                            fpQueryParams: this.route.snapshot.queryParams.fp === 'enable'
                        })
                    )
                );
            }

            if (
                this.appConfig.appConfigs.FLAG_ISFingerPrintEnabledWithoutQp ||
                (this.appConfig.appConfigs.FLAG_ISFingerPrintEnabled && this.route.snapshot.queryParams.fp === 'enable')
            ) {
                this.fingerPrintStates.exist = !!localStorage.getItem('fpId');
                this.fingerPrintStates.show = await this.checkIfFingerPrintAvailable();
            }
        }
    }

    async appleGetChallengeAfterBoxOpen(): Promise<any> {
        if (isAppleDevice()) {
            this.appleSignInStates.exist = !!localStorage.getItem(this.APPLE_SIGN_IN_STORAGE_KEY);
            if (this.appleSignInStates.exist) {
                this.gtmService.pushDirective({
                    category: 'New website - registered',
                    action: 'Personal menu',
                    label: 'Register appleSignIn - show exist'
                });
                return;
            }

            if (!this.isGetChallenge && !this.authService.isGali()) {
                if (
                    !this.appConfig.appConfigs.signInWithAppleEnabled ||
                    (this.appConfig.appConfigs.signInWithAppleEnabled === 1 &&
                        !this.route.snapshot.queryParams.appleSignIn)
                ) {
                    return;
                }

                if (!window['AppleID']) {
                    await this.appleSignInService.loadScript('/assets/js/apple-signin.js');
                    if (!window['AppleID']) {
                        return console.warn('AppleID object not loaded!');
                    }
                }
                console.log(AppleID);

                AppleID.auth.init({
                    clientId: 'il.co.max.signInService',
                    redirectURI: `${window.location.origin}/api/apple/signIn`,
                    usePopup: true
                });

                await this.getChallenge();
            }
        }
    }
    ngOnDestroy(): void {
        this.subs.forEach(sub => sub.unsubscribe());
        this.showUserTooltip = false;
        this.isUserBoxOpen = false;
        if (this.uiHelper.isBrowser) {
            document.querySelector('.pop-up-back').classList.remove('show-back');
            document.querySelector('.pop-up-back').classList.remove('show-back-user-box');
            if (this.uiHelper.IsMobile(768) || this.isMobileLandscape) {
                document.querySelector('body').classList.remove('overflow');
                document.querySelector('html').classList.remove('overflow');
            }
        }
    }

    isAuthenticated(): boolean {
        return this.authService.isUserAuthenticated() && !this.openBankingService.isOpenBankingLoginMode();
    }

    toggleUserBox(): void {
        this.mainLayoutService.changeUserBoxMode(!this.isUserBoxOpen);
        if (this.fingerPrintStates) {
            this.fingerPrintStates.showMessage = false;
        }
        if (this.appleSignInStates) {
            this.appleSignInStates.showMessage = false;
        }
    }

    onLoginClick(): void {
        this.gtmService.pushDirective({ category: 'New website - anonymous', action: 'Enter to registered site', label: 'Click' });
        if (!this.appConfig.appConfigs.enableLoginPopup) {
            if (this.appConfig.appConfigs.FLAG_ISRedirectToNewLogin) {
                this.router.navigate(['login'], {
                    queryParams: {
                        ReturnURL: `${this.originUrl}/homepage?SourceGA=AnonymousHeaderCH`
                    }
                });
            } else {
                window.location.href =
                    this.infoRoot +
                    '/Anonymous/Login/CardholdersLogin.aspx?ReturnUrl=' +
                    this.originUrl +
                    '/homepage?SourceGa=PersonalMenu';
            }
            return;
        }
        if (
            window.location.href.includes(`${this.baseUiRoot}/login`) ||
            window.location.href.includes(`${this.baseUiRoot}/open-banking/login`) ||
            window.location.href.includes(`${this.baseUiRoot}/renew-password`)
        ) {
            return;
        }
        this.loginSvc.showPopup();
        // this.modalSvc.openModal(LoginPopup);
        // document.querySelector('.modal-backdrop').classList.add('modal-login-backdrop');
    }

    logout(): void {
        this.loginSvc.logoff().subscribe(() => {
            this.toggleUserBox();
            window.location.href = `${this.baseUiRoot}/homepage/welcome`; // reload for cleaning services fields
        });
    }

    changeAccount(): void {
        window.location.href = this.appConfig.appConfigs.comadminBaseUrl + '/admin/login';
    }

    async addFingerPrint(): Promise<any> {
        this.gtmService.pushDirective({
            category: 'New website - registered',
            action: 'Personal menu',
            label: 'Click on add fingerprint'
        });
        this.fingerPrintStates.loader = true;
        const challengeResponse = await this.fingerPrintService.getChallenge().toPromise();
        this.fingerPrintStates.loader = false;
        if (challengeResponse.returnCode === 0) {
            try {
                navigator.credentials
                    .create({
                        publicKey: {
                            authenticatorSelection: {
                                authenticatorAttachment: 'platform',
                                userVerification: 'required'
                            },
                            challenge: Uint8Array.from(
                                challengeResponse.result as any,
                                (c: any) => c.charCodeAt(0) as any
                            ),
                            rp: { id: document.domain, name: 'Max fido services' },
                            user: {
                                id: Uint8Array.from('userID goes here' as any, (c: any) => c.charCodeAt(0) as any),
                                name: this.userbox.UserName,
                                displayName: this.userbox.UserName
                            },
                            pubKeyCredParams: [{ type: 'public-key', alg: -7 }],
                            attestation: 'direct'
                        }
                    })
                    .then(credential => {
                        this.verify(credential);
                    })
                    .catch(error => {
                        this.fingerPrintStates = {
                            loader: false,
                            message: 'אופס, משהו לא התסדר ממליצים לנסות שוב',
                            showMessage: true,
                            show: true,
                            exist: false,
                            enableLog: this.fingerPrintStates.enableLog
                        };
                        this.gtmService.pushDirective({
                            category: 'New website - registered',
                            action: 'Personal menu',
                            label: 'Register fingerprint failed - general error'
                        });
                        this.logger.error(
                            `Register fingerprint failed - general error - navigator.credentials.create - message: ${error.message}`
                        );
                    });
            } catch (error) {
                if (error.message === `Cannot read property 'create' of undefined`) {
                    this.fingerPrintStates = {
                        loader: false,
                        message: 'אופס, סוג המכשיר שלך או הדפדפן אינו תומך בטביעת אצבע.',
                        showMessage: true,
                        show: false,
                        exist: false,
                        enableLog: this.fingerPrintStates.enableLog
                    };
                    sessionStorage.setItem('unsupportedFp', 'true');
                    this.gtmService.pushDirective({
                        category: 'New website - registered',
                        action: 'Personal menu',
                        label: 'Register fingerprint failed - device not supported'
                    });
                    this.logger.error(
                        'Register fingerprint failed - general error - Cannot read property create of undefined'
                    );
                } else if (error.message === `Not implemented`) {
                    this.fingerPrintStates = {
                        loader: false,
                        message: 'אופס, נראה שדפדפן זה אינו תומך עדיין בטביעת אצבע.',
                        showMessage: true,
                        show: false,
                        exist: false,
                        enableLog: this.fingerPrintStates.enableLog
                    };
                    localStorage.setItem('browserNotSupported', 'true');
                    this.gtmService.pushDirective({
                        category: 'New website - registered',
                        action: 'Personal menu',
                        label: 'Register fingerprint failed - browser not supported'
                    });
                    this.logger.error(`Browser not implement fp logic. user agent: ${window.navigator.userAgent}`);
                } else {
                    this.gtmService.pushDirective({
                        category: 'New website - registered',
                        action: 'Personal menu',
                        label: 'Register fingerprint failed - general error'
                    });
                    this.logger.error(
                        `Register fingerprint failed - general error - catch addFingerPrint. message: ${error.message ||
                        error}`
                    );
                }
            }
        } else if (challengeResponse.returnCode === 10) {
            this.fingerPrintStates = {
                showMessage: true,
                message:
                    'אופס! ניתן להירשם לשירות רק באמצעות התחברות עם שם משתמש וסיסמה, ולא באמצעות קוד חד פעמי לנייד.',
                loader: false,
                show: false,
                exist: false,
                enableLog: this.fingerPrintStates.enableLog
            };
            this.gtmService.pushDirective({
                category: 'New website - registered',
                action: 'Personal menu',
                label: 'Register fingerprint failed - user logged by otp'
            });
            this.logger.warning('Register fingerprint failed - user logged by otp');
        } else {
            this.fingerPrintStates = {
                showMessage: true,
                message: 'יש לנו תקלה טכנית, מומלץ לנסות שוב מאוחר יותר',
                loader: false,
                show: true,
                exist: false,
                enableLog: this.fingerPrintStates.enableLog
            };
            this.gtmService.pushDirective({
                category: 'New website - registered',
                action: 'Personal menu',
                label: 'Register fingerprint failed - general error'
            });
            this.logger.error(`Register fingerprint failed - general error - getChallenge`);
        }
    }

    deleteFingerPrint(): void {
        // if (!this.fingerPrintStates.preRemove) {
        //     this.fingerPrintStates.preRemove = true;
        //     return;
        // }
        this.gtmService.pushDirective({
            category: 'New website - registered',
            action: 'Personal menu',
            label: 'Click on remove fingerprint'
        });
        this.fingerPrintStates.loader = true;
        this.fingerPrintService.deleteFingerPrint(localStorage.getItem('fpId')).subscribe(res => {
            console.log(res);
            if (res.returnCode !== 0) {
                this.fingerPrintStates = {
                    exist: true,
                    show: false,
                    showMessage: true,
                    loader: false,
                    message: 'אופס, קרתה לנו תקלה.',
                    enableLog: this.fingerPrintStates.enableLog
                };
                this.gtmService.pushDirective({
                    category: 'New website - registered',
                    action: 'Personal menu',
                    label: 'Remove fingerprint - failed'
                });
            } else {
                this.fingerPrintStates = {
                    exist: false,
                    show: true,
                    showMessage: true,
                    message: 'אפשרות זיהוי חכם הוסרה בהצלחה',
                    loader: false,
                    enableLog: this.fingerPrintStates.enableLog
                };
                localStorage.removeItem('fpId');
                this.gtmService.pushDirective({
                    category: 'New website - registered',
                    action: 'Personal menu',
                    label: 'Remove fingerprint - success'
                });
            }
        });
    }

    async getChallenge(): Promise<any> {
        const challengeRequest = await this.appleSignInService.getChallenge().toPromise();
        this.gtmService.pushDirective({
            category: 'New website - registered',
            action: 'Personal menu',
            label: 'Register appleSignIn - get challenge'
        });
        if (challengeRequest.returnCode === 0 && challengeRequest.result) {
            this.appleSignInChallenge = challengeRequest.result;
            this.appleSignInStates.show = true;
            this.isGetChallenge = true;
            this.gtmService.pushDirective({
                category: 'New website - registered',
                action: 'Personal menu',
                label: 'Register appleSignIn - show register'
            });
        } else {
            this.gtmService.pushDirective({
                category: 'New website - registered',
                action: 'Personal menu',
                label: 'Register appleSignIn failed - get challenge'
            });
        }
    }

    async registerAppleSignIn(): Promise<any> {
        console.log('registerAppleSignIn');
        this.appleSignInStates.showMessage = false;
        this.appleSignInStates.loader = true;
        this.appleSignInStates.showPopup = true;
        this.gtmService.pushDirective({
            category: 'New website - registered',
            action: 'Personal menu',
            label: 'Register appleSignIn - click register'
        });
        try {
            const result = await AppleID.auth.signIn({ nonce: this.appleSignInChallenge });
            console.log({ result });
            const verifyTokenRequest = await this.appleSignInService.register(result.authorization).toPromise();
            console.log(verifyTokenRequest);
            if (verifyTokenRequest.returnCode === 0) {
                this.appleSignInStates.message = 'נרשמת בהצלחה לזיהוי חכם, הכניסה הבאה תהיה קלילה יותר';
                this.appleSignInStates.show = false;
                this.appleSignInStates.exist = true;
                localStorage.setItem(this.APPLE_SIGN_IN_STORAGE_KEY, verifyTokenRequest.result);
                this.gtmService.pushDirective({
                    category: 'New website - registered',
                    action: 'Personal menu',
                    label: 'Register appleSignIn - successful'
                });
            } else if (verifyTokenRequest.returnCode === 10) {
                this.appleSignInStates.message = 'אופס\nלא הצלחנו לאמת את הApple ID שלכם';
                this.gtmService.pushDirective({
                    category: 'New website - registered',
                    action: 'Personal menu',
                    label: 'Register appleSignIn failed - security issue'
                });
            } else if (verifyTokenRequest.returnCode === 12) {
                this.appleSignInStates.message = 'אוי\nנראה שהחשבון Apple הזה כבר בשימוש על ידי משתמש אחר';
                this.gtmService.pushDirective({
                    category: 'New website - registered',
                    action: 'Personal menu',
                    label: 'Register appleSignIn failed - appleId already in use'
                });
            } else {
                this.appleSignInStates.message = 'אופס, אירעה שגיאה\nאנא נסו שוב במועד מאוחר יותר';
                this.gtmService.pushDirective({
                    category: 'New website - registered',
                    action: 'Personal menu',
                    label: 'Register appleSignIn failed - general error'
                });
            }
            this.appleSignInStates.showMessage = true;
            this.appleSignInStates.loader = false;
            this.appleSignInStates.showPopup = false;
        } catch (error) {
            console.log({ error });
            this.appleSignInStates.message = 'אופס, אירעה שגיאה\nאנא נסו שוב במועד מאוחר יותר';
            this.appleSignInStates.showMessage = true;
            this.appleSignInStates.loader = false;
            this.appleSignInStates.showPopup = false;
            this.gtmService.pushDirective({
                category: 'New website - registered',
                action: 'Personal menu',
                label: 'Register appleSignIn failed - apple general error'
            });
        }
    }

    async removeAppleSignIn(): Promise<any> {
        this.appleSignInStates.loader = true;
        this.appleSignInService
            .removeAppleSignIn(localStorage.getItem(this.APPLE_SIGN_IN_STORAGE_KEY))
            .subscribe(async res => {
                console.log(res);
                if (res.returnCode === 0) {
                    this.appleSignInStates = {
                        exist: false,
                        show: !!window['AppleID'],
                        showMessage: true,
                        message: 'אפשרות זיהוי חכם הוסרה בהצלחה',
                        loader: false,
                        enableLog: this.appleSignInStates.enableLog,
                        showPopup: false
                    };
                    localStorage.removeItem(this.APPLE_SIGN_IN_STORAGE_KEY);
                    this.gtmService.pushDirective({
                        category: 'New website - registered',
                        action: 'Personal menu',
                        label: 'Remove appleSignIn - success'
                    });

                    if (!window['AppleID']) {
                        await this.appleSignInService.loadScript('/assets/js/apple-signin.js');
                        if (!window['AppleID']) {
                            return console.warn('AppleID object not loaded!');
                        }
                        AppleID.auth.init({
                            clientId: 'il.co.max.signInService',
                            redirectURI: `${window.location.origin}/api/apple/signIn`,
                            usePopup: true
                        });
                        this.appleSignInStates.show = true;
                    }
                    await this.getChallenge();
                } else {
                    this.appleSignInStates = {
                        exist: true,
                        show: false,
                        showMessage: true,
                        loader: false,
                        message: 'אופס, קרתה לנו תקלה.',
                        enableLog: this.appleSignInStates.enableLog,
                        showPopup: false
                    };
                    this.gtmService.pushDirective({
                        category: 'New website - registered',
                        action: 'Personal menu',
                        label: 'Remove appleSignIn - failed'
                    });
                }
            });
    }

    private verify(credential: any): void {
        this.fingerPrintStates.loader = true;
        const attestation = {
            type: credential.type,
            id: credential.id,
            response: {
                clientDataJSON: this.base64Service.bufferToString(credential['response'].clientDataJSON),
                attestationObject: this.base64Service.bufferToString(credential['response'].attestationObject)
            }
        };
        this.fingerPrintService.verifyAttestation(attestation).subscribe(res => {
            this.fingerPrintStates.loader = false;
            if (res.returnCode === 0) {
                this.fingerPrintStates = {
                    showMessage: true,
                    message: 'נרשמת בהצלחה לזיהוי חכם, הכניסה הבאה תהיה קלילה יותר',
                    loader: false,
                    show: false,
                    exist: true,
                    enableLog: this.fingerPrintStates.enableLog
                };
                localStorage.setItem('fpId', res.result);
                sessionStorage.removeItem('unsupportedFp');
                localStorage.removeItem('browserNotSupported');
                this.gtmService.pushDirective({
                    category: 'New website - registered',
                    action: 'Personal menu',
                    label: 'Register fingerprint - successful'
                });
                this.logger.log('Register fingerprint - successful');
            } else if (res.returnCode === 10 || res.returnCode === 11) {
                this.fingerPrintStates = {
                    showMessage: true,
                    message: 'אופס! כרגע לא ניתן להצטרף לשירות זיהוי חכם',
                    loader: false,
                    show: false,
                    exist: false,
                    enableLog: this.fingerPrintStates.enableLog
                };
                this.gtmService.pushDirective({
                    category: 'New website - registered',
                    action: 'Personal menu',
                    label: 'Register fingerprint failed - security issue'
                });
                this.logger.error('Register fingerprint failed - security issue - verify');
            } else {
                this.fingerPrintStates = {
                    showMessage: true,
                    message: 'אוי! קרתה לנו תקלה, ממליצים לנסות שוב מאוחר יותר',
                    loader: false,
                    show: false,
                    exist: false,
                    enableLog: this.fingerPrintStates.enableLog
                };
                this.gtmService.pushDirective({
                    category: 'New website - registered',
                    action: 'Personal menu',
                    label: 'Register fingerprint failed - general error'
                });
                this.logger.error('Register fingerprint failed - general error - verify');
            }
        });
    }

    async addFidoCredentials(){
        await this.fido2Srv.registerBiometric(this.userbox.UserName);
        this.fido2Srv.attestationSuccess.subscribe(res => {
            if(res === true){
                this.fido2Exists = true;
                this.fido2Srv.fido2Exists = true;
            }
        })
    }

    deleteFidoCredentials(){
        this.fido2Srv.deleteCredentials().subscribe(res => {
            if (res.ReturnCode === 0) {
                this.fido2Exists = false;
                this.fido2Srv.fido2Exists = false;
            }
        });
    }

    private insideToogleUserBox(isUserBoxOpen: boolean): void {
        if (this.isUserBoxOpen !== isUserBoxOpen) {
            this.isUserBoxOpen = isUserBoxOpen;
            document.querySelector('.pop-up-back').classList.toggle('show-back');
            document.querySelector('.pop-up-back').classList.toggle('show-back-user-box');
            if (this.uiHelper.IsMobile(768)) {
                document.querySelector('body').classList.toggle('overflow');
                document.querySelector('html').classList.toggle('overflow');
                if (this.isUserBoxOpen) {
                    document.querySelector('footer').style.zIndex = '3';
                } else {
                    document.querySelector('footer').style.zIndex = '';
                }
            }
        }
    }

    private hitLettersGtm(letters: string): void {
        this.gtmService.pushDirective({
            category: 'New website - registered',
            action: 'Mail icon',
            label: letters ? letters : '-'
        });
    }

    private doWhenLoggedIn(): void {
        if (!this.openBankingService.isOpenBankingLoginMode()) {
            this.mainLayoutService.loadUserBox().then(res => {
                this.userbox = res;
                this.mainLayoutService.unReadMessage.next((res as { UnreadLetters: number }).UnreadLetters);
                this.showUserName = !isNullOrUndefined(this.userbox.UserName);
                this.hitLettersGtm(this.userbox.UnreadLetters);
                if (!isNullOrUndefined(this.userbox.IdNumberForGlassBoxEvent)) {
                    this.detectorService.addIdEventForGlassBox(this.userbox.IdNumberForGlassBoxEvent);
                }
            });
        }
    }

    private checkFidoCredentials(){
        this.fido2Srv.checkCredentials().subscribe(res => {
            this.fido2Exists = res.Result;
            if(this.fido2Exists){
                this.gtmService.pushDirective({
                    category: 'New website - registered',
                    action: 'Personal menu',
                    label: isIos() ? 'Register appleSignIn - show exist' : 'Register fingerprint - show exist'
                });
            }
            this.fido2Srv.fido2Checked = true;
            this.fido2Srv.fido2Exists = this.fido2Exists;
        })
    }

    private async checkIfFingerPrintAvailable(): Promise<boolean> {
        try {
            if (sessionStorage.getItem('unsupportedFp')) {
                if (this.fingerPrintStates.enableLog) {
                    this.logger.error(`fingerprint not available because the SessionStorage set unsupportedFp`);
                }
                return false;
            }
            if (localStorage.getItem('fpId')) {
                if (this.fingerPrintStates.enableLog) {
                    this.logger.error(
                        `fingerprint not available because the there is already fp in localStorage ${localStorage.getItem(
                            'fpId'
                        )}`
                    );
                }
                return false;
            }
            if (localStorage.getItem('browserNotSupported')) {
                if (this.fingerPrintStates.enableLog) {
                    this.logger.error(
                        `fingerprint not available because the spcific browser not implement finger print logic yet. user agent: ${window.navigator.userAgent}`
                    );
                }
                return false;
            }
            if (isAppleDevice()) {
                if (this.fingerPrintStates.enableLog) {
                    this.logger.error(`fingerprint not available because its ios`);
                }
                return false;
            }

            if (!!PublicKeyCredential) {
                const result = await PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable();
                if (this.fingerPrintStates.enableLog) {
                    this.logger.error(
                        `Fingerprint ${result ? 'not' : ''} enabled because the device ${result ? 'not' : ''} supported`
                    );
                }
                return result;
            }
            if (this.fingerPrintStates.enableLog) {
                this.logger.error(`Fingerprint not enabled because PublicKeyCredential was not found`);
            }
            return false;
        } catch (error) {
            if (this.fingerPrintStates.enableLog) {
                this.logger.error(`fingerPrint not enabled log from catch ${error.message}`);
            }
            return false;
        }
    }

    getCompanyLogoBackground() {
        if (this.isEve8) {
            return { 'background-color': '#FFFB94' };
        } else if (this.isEve10) {
            return { 'background-color': '#daf0ff' };
        } else {
            return { 'background-color': 'transparent' };
        }
    }
}
