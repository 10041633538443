import { Component, OnInit, ViewChild } from '@angular/core';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { GtmService } from '../../../../shared/cardholders-core/services/gtm.service';
import { HomepageService } from '../../../homepage/services/homepage.service';
import { NewItem, News } from '../../interfaces/homepage-content.interface';

@Component({
    selector: 'app-more-actions-in-max',
    templateUrl: './more-actions-in-max.component.html',
    styleUrls: ['./more-actions-in-max.component.scss']
})
export class MoreActionsInMaxComponent implements OnInit {
    scrollTimeout: any;

    @ViewChild('slickModal')
    slick: SlickCarouselComponent;
    slideConfig = {
        infinite: false,
        variableWidth: true,
        slidesToScroll: 1,
        speed: 200,
        swipeSpeed: 200,
        arrows: false,
        mobileFirst: true,
        rtl: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    variableWidth: true,
                    arrows: true,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 920,
                settings: {
                    slidesToShow: 2,
                    variableWidth: true,
                    arrows: true,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    variableWidth: true,
                    arrows: true,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 1399,
                settings: {
                    slidesToShow: 3,
                    variableWidth: true,
                    arrows: true,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 1640,
                settings: {
                    slidesToShow: 4,
                    variableWidth: true,
                    arrows: true,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 320,
                settings: 'unslick'
            }
        ]
    };

    isMouseDown = false;
    isDragging = false;

    get content(): News {
        return this.hpService.homepageContent?.news;
    }

    constructor(private hpService: HomepageService, private gtmService: GtmService) {}

    ngOnInit(): void {}

    getType(item: NewItem): 'image' | 'bullets' | 'text' {
        if (item.image) {
            return 'image';
        }
        if (item.bulletsList && item.bulletsList.length > 0) {
            return 'bullets';
        }
        return 'text';
    }

    onCTAClick(item: NewItem): void {
        this.gtmService.pushNewWebsiteAnonymous('New - News', 'Click - ' + item.title);
    }

    onScroll(): void {
        clearTimeout(this.scrollTimeout);

        this.scrollTimeout = setTimeout(() => {
            this.gtmService.pushNewWebsiteAnonymous('New - News', 'Swipe');
        }, 100);
    }

    onMouseDown(event: MouseEvent): void {
        this.isMouseDown = true;
        event.preventDefault();
    }

    onMouseMove(event: MouseEvent): void {
        if (this.isMouseDown) {
            this.isDragging = true;
        }
    }

    onMouseUp(event: MouseEvent): void {
        this.isMouseDown = false;
        this.isDragging = false;
    }

    registrationToGtm(event: any) {
        try {
            event.slick.$nextArrow[0].onclick = () => { this.gtmService.pushNewWebsiteAnonymous('New - News', ' Arrow click - left');};
            event.slick.$prevArrow[0].onclick = () => {this.gtmService.pushNewWebsiteAnonymous('New - News', ' Arrow click - right');};
        } catch (error) { }
    }
    

}
