<section *ngIf="showMyCards">
    <div class="container">
        <h2>
            חיובים, יתרת מסגרת וכרטיסים <span *ngIf="approvedCardsCount">({{ approvedCardsCount }})</span>
        </h2>
        <div
            class="card-details"
            [ngClass]="{
                'one-card': this.approvedCardsCount === 1,
                'two-cards': this.approvedCardsCount === 2,
                'three-cards': this.approvedCardsCount === 3,
                'only-four-cards': this.approvedCardsCount === 4,
                'four-cards': this.approvedCardsCount > 4
            }"
        >
            <div class="card-box-wrapper" *appLoader="myCardsIsLoaded; template: myCardsloaderTemplateEl">
                <div class="expected-obligation card-box">
                    <div>
                        <h3>
                            החיובים הצפויים לרדת{{
                                flagIsUserById && userData?.UserCards?.IsMultAccounts ? ' בכל החשבונות שלי' : ''
                            }}
                        </h3>
                        <div class="obligation-sum">
                            <span class="currency-wrapper">
                                <span *ngIf="showMyMaxTooltip" maxTooltip="פירוט החיובים של כרטיס MyMAX אינו נכלל בסה&#39;&#39;כ החיובים של כרטיסי MAX. ניתן לצפות בפירוט דרך לחיצה על הכרטיס עצמו" class="info-tip"></span>
                                <ng-container *ngFor="
                                        let currency of this.userData.UserCards.Summary
                                            | summaryFilter: this.userData.UserCards.Summary;
                                        index as i
                                    ">
                                    <span [ngClass]="currency.CurrencySymbol" class="currency-block">
                                        <span class="plus" *ngIf="i > 0"> + </span>
                                        {{ currency.TotalDebitSum | maxCurrencyNegativePriceSec: currency.Currency }}
                                    </span>
                                </ng-container>
                                <span *ngIf="this.checkTotalSummaryStatus()"> ₪0 </span>
                            </span>
                        </div>
                    </div>
                    <!-- <a routerLink="/transaction-details/personal" [state]="{oldParams: '?SourceGa=ClickAllCards'}" class="link" appGtm [data]="{category: 'New website - registered', action: 'Click on card', label: 'Click all cards'}" listen="onclick">לפירוט החיובים שלי</a> -->
                    <div class="divide-button-wrapper">
                        <a
                            routerLink="/transaction-details/personal"
                            class="default-link"
                            appGtm
                            [data]="{
                                category: 'New website - registered',
                                action: 'Click on card',
                                label: 'Click all cards'
                            }"
                            listen="onclick"
                            >לפירוט החיובים שלי</a
                        >
                        <div class="pay-button-wrapper" *ngIf="monthlyBillingLayoutHomePage?.showMonthlyBillingPastLayout">
                            <div (ngInit)="showMonthlyBillingLayout('116')">
                                <a
                                    (click)="goToMonthlyBillingPastLayout()"
                                    class="divide-pay-button"
                                    [ngStyle]="{
                                        'background-color': monthlyBillingLayoutHomePage?.mainButtonColor,
                                        color: monthlyBillingLayoutHomePage?.mainTextColor
                                    }"
                                    title=""
                                    >לחלוקת החיוב הקודם בכרטיס</a
                                >
                            </div>
                        </div>
                        <div class="pay-button-wrapper" *ngIf="monthlyBillingLayoutHomePage?.showMonthlyBillingLayout">
                            <div (ngInit)="showMonthlyBillingLayout('117')">
                                <a
                                    (click)="goToMonthlyBillingLayout()"
                                    class="divide-pay-button"
                                    [ngStyle]="{
                                        'background-color': monthlyBillingLayoutHomePage?.mainButtonColor,
                                        color: monthlyBillingLayoutHomePage?.mainTextColor
                                    }"
                                    title=""
                                    >{{ monthlyBillingLayoutHomePage?.mainButtonText }}</a
                                >
                            </div>
                        </div>
                    </div>
                </div>
                <div class="only-card-wrapper">
                    <div
                        (click)="this.redirectToDebitPage(card.Index, card.CycleSummary[0].Date, $event)"
                        class="card card-box card-box-url"
                        *ngFor="let card of this.userCardsToShow; index as j"
                        appGtm
                        [data]="{
                            category: 'New website - registered',
                            action: 'Click on card',
                            label:
                                card.OpenToBuy > 0 && card.OpenToBuy < card.CreditLimit
                                    ? '0-100% CreditLimit - Click'
                                    : card.OpenToBuy === 0
                                    ? '0% Credit limit -Click'
                                    : card.OpenToBuy < 0
                                    ? 'Less 0% -Click'
                                    : 'Over 100% -Click'
                        }"
                    >
                        <div class="card-detail-wrapper">
                            <img src="{{ card?.CardImage }}" alt="" class="card-pic" />
                            <div
                                *ngIf="card?.IsActiveDigitalCard"
                                class="digital-card-ind"
                                [attr.id]="'digital-card_' + card.Index"
                            >
                                <img src="/assets/images/homepage/contactless.svg" alt="" class="digital-ind-pic" />
                                <span>כרטיס דיגיטלי</span>
                                <a
                                    class="tooltip-icon"
                                    appTooltip
                                    [tooltipText]="digitalCardTooltipText"
                                    [tooltipHtmlParent]="'digital-card_' + card.Index"
                                ></a>
                            </div>
                            <span class="name">{{ card.OwnerFullName }}</span>
                            <h4>{{ card.Last4Digits + ' ' + card.CardName + (card.IsMyMAX?(' - ' + card.MyMaxHebrewName):'') }}</h4>
                            <!-- <h4>{{ card.Last4Digits + ' ' + card.CardName }}</h4> -->
                            <span class="valid" *ngIf="card.ExpirationDate">תוקף {{ card.ExpirationDate }}</span>
                            <a
                                id="clickableLink"
                                [routerLink]="['/instant-issuing/personal']"
                                [queryParams]="{ cardIndex: card.Index }"
                                *ngIf="flagIsUseViewCardDetailsInd? card?.IsViewCardDetailsOK : card?.IsOwnerDigitalCard"
                                class="link"
                                >צפייה בפרטי הכרטיס</a
                            >
                        </div>
                        <div class="obligation" *ngIf="card.CardLogo !== 44 && !card.IsMyMAX">
                            <div
                                (click)="this.redirectToDebitPage(card.Index, card.CycleSummary[0].Date, $event)"
                                class="sum"
                            >
                                <span *ngIf="this.checkIfFixedDebit(j)" class="obligation-alert"><span class="text">חיוב קבוע</span></span>
                                <span class="currency-wrapper price">
                                    <ng-container *ngFor="
                                            let currency of card.CycleSummary | cycleSummaryFilter: card.CycleSummary;
                                            index as i
                                        ">
                                        <span [ngClass]="currency.CurrencySymbol" class="currency-block">
                                            <span class="plus" *ngIf="i > 0"> + </span>
                                            {{
                                            currency.TotalDebitSum | maxCurrencyNegativePriceSec: currency.Currency
                                            }}
                                        </span>
                                    </ng-container>
                                    <span *ngIf="checkCardTotalSummaryStatus(card.CycleSummary)"> ₪0 </span>
                                </span>
                                <!-- <span class="price">
                                    <ng-container
                                        *ngFor="
                                            let currency of card.CycleSummary | cycleSummaryFilter: card.CycleSummary;
                                            index as i
                                        "
                                    >
                                        <span *ngIf="i > 0"> + </span>
                                        {{ currency.TotalDebitSum | maxCurrencyNegativePrice: currency.CurrencySymbol }}
                                    </ng-container>
                                    <span *ngIf="this.checkCardSummaryStatus(j)"> ₪0 </span>
                                </span> -->
                                <span class="date" *ngIf="card.CycleSummary[0]"
                                    >{{ this.getDebitSumText(j) }}
                                    <span>{{ card.CycleSummary[0].Date | date: 'dd.MM.yy' }}</span></span
                                >
                            </div>
                            <div class="frame-balance" [attr.id]="'cardIndex_' + card.Index">
                                <h5>יתרת מסגרת</h5>
                                <a
                                    (click)="hasTooltip = true"
                                    *ngIf="card.CreditLimitType === 3 || card.CreditLimitType === 4"
                                    class="tooltip-icon"
                                    appTooltip
                                    [tooltipText]="idTooltipText"
                                    [tooltipHtmlParent]="'cardIndex_' + card.Index"
                                ></a>
                                <div class="slider">
                                    <div
                                        [style.width]="this.calculateWidth(card.OpenToBuy, card.CreditLimit)"
                                        [ngClass]="{ 'exceed-frame': card.OpenToBuy < 0, graph: true }"
                                    ></div>
                                </div>
                                <div *ngIf="card.OpenToBuy >= 0" class="slider-sum">
                                    <span>₪</span><span>{{ card.OpenToBuy | number }}</span>
                                    <span> מתוך <span>₪</span>{{ card.CreditLimit | number }}</span>
                                </div>
                                <div *ngIf="card.OpenToBuy < 0" class="slider-sum">
                                    <span> חרגת ב <span>₪</span>{{ -card.OpenToBuy | number }}</span>
                                    <span>
                                        מ <span>₪</span><span>{{ card.CreditLimit | number }}</span></span
                                    >
                                </div>
                                <div *ngIf="card.IsControlsBiZCardSubscribe" (click)="goToControl($event)">
                                    <a class="link">לפירוט תשלומי העסק (חשכ"ל)</a>
                                </div>
                                <div class="card-otb" *ngIf="!ismobile && card.ClearingAmtForOtb > 0">
                                    <span>
                                        הועברו ₪{{ card.ClearingAmtForOtb | number }}
                                        מהסליקה לשימוש ביתרת הכרטיס
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="card-otb" *ngIf="ismobile && card.ClearingAmtForOtb > 0">
                            <span>
                                הועברו ₪{{ card.ClearingAmtForOtb | number }}
                                מהסליקה לשימוש ביתרת הכרטיס
                            </span>
                        </div>
                        <ng-container *ngIf="this.approvedCardsCount === 1">
                            <div class="pay-button-wrapper" *ngIf="monthlyBillingLayoutHomePage?.showMonthlyBillingPastLayout">
                                <div>
                                    <a
                                        (click)="goToMonthlyBillingPastLayout(); $event.stopPropagation()"
                                        class="divide-pay-button"
                                        [ngStyle]="{
                                            'background-color': monthlyBillingLayoutHomePage?.mainButtonColor,
                                            color: monthlyBillingLayoutHomePage?.mainTextColor
                                        }"
                                        title=""
                                        >לחלוקת החיוב הקודם בכרטיס</a
                                    >
                                </div>
                            </div>
                            <div class="pay-button-wrapper" *ngIf="monthlyBillingLayoutHomePage?.showMonthlyBillingLayout">
                                <div>
                                    <a
                                        (click)="goToMonthlyBillingLayout(); $event.stopPropagation()"
                                        class="divide-pay-button"
                                        [ngStyle]="{
                                            'background-color': monthlyBillingLayoutHomePage?.mainButtonColor,
                                            color: monthlyBillingLayoutHomePage?.mainTextColor
                                        }"
                                        title=""
                                        >{{ monthlyBillingLayoutHomePage?.mainButtonText }}</a
                                    >
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
                <div>
                    <div
                        [ngClass]="{ 'show-all-cards': true, 'show-less-card': this.isShowAllCards }"
                        (click)="toggleCards()"
                        *ngIf="this.isShowCardsText"
                        appGtm
                        [data]="{
                            category: 'New website - registered',
                            action: 'My cards',
                            label: this.isShowAllCards ? 'Expend' : 'Shrink'
                        }"
                        listen="onclick"
                    >
                        <span *ngIf="this.isShowAllCards">תראו לי פחות כרטיסים</span>
                        <span *ngIf="!this.isShowAllCards">תראו לי את כל הכרטיסים</span>
                    </div>
                </div>
            </div>

            <ng-container *ngIf="showRecommendation">
                <ng-container *appLoader="recommendationIsLoaded; template: recommendationLoaderTemplateEl">
                    <app-recommendation-box
                        *ngIf="this.recommendationsToShow"
                        class="recommendation-box"
                        [loanParams]="this.loanParams"
                        [recommendation]="recommendationsToShow[0]"
                        [backgroundColor]="
                            backgroundFirstCardRec?.BackgroundColor
                                ? backgroundFirstCardRec?.BackgroundColor
                                : '#ff9779'
                        "
                        (clickEvent)="onClickRecommendation(recommendationsToShow[0])"
                    ></app-recommendation-box>
                    <app-recommendation-box
                        *ngIf="
                            this.recommendationsToShow &&
                            this.recommendationsToShow.length > 1 &&
                            this.userData &&
                            this.userData.UserCards.Cards.length > 2
                        "
                        class="recommendation-box out-of-frame"
                        [loanParams]="this.loanParams"
                        [recommendation]="recommendationsToShow[1]"
                        [backgroundColor]="
                            backgroundSecondCardRec?.BackgroundColor
                                ? backgroundSecondCardRec?.BackgroundColor
                                : '#51d7f8'
                        "
                        (clickEvent)="onClickRecommendation(recommendationsToShow[1])"
                    ></app-recommendation-box>
                </ng-container>
            </ng-container>
        </div>
    </div>
</section>

<ng-template #myCardsloaderTemplateEl>
    <div class="card-box-wrapper">
        <div
            class="expected-obligation expected-obligation-template card-box"
            [ngClass]="{ 'app-loader-blink': myCardsShowLoaderBlink }"
        ></div>
        <div [ngClass]="{ 'only-card-wrapper': !myCardsShowLoaderBlink }">
            <div class="card card-box card-template" [ngClass]="{ 'app-loader-blink': myCardsShowLoaderBlink }">
                <div class="card-detail-wrapper"></div>
            </div>
            <div class="card card-box card-template" [ngClass]="{ 'app-loader-blink': myCardsShowLoaderBlink }">
                <div class="card-detail-wrapper"></div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #recommendationLoaderTemplateEl>
    <app-recommendation-box
        class="recommendation-box recommendation-box-template app-loader-blink"
    ></app-recommendation-box>
</ng-template>
