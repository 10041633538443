import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { AuthService } from './auth.service';
import { filter } from 'rxjs/operators';
import { InitializerService, RoutingHistoryService } from 'core-max-lib';
import { BlockingCardStatus } from '../../../modules/block-card/enum/BlockingCardStatus.enum';
import { ReplaceCardReason } from '../../../modules/replace-card/models/enums.enum';

export interface IGtm {
    category: string;
    action: string;
    label: string;
    value?: string;
}

export interface IGtmWorkflow {
    category: string;
    action: string;
    label: string;
    subjectId: string;
    workflowDescription: string;
    workflowStep: string;
    workflowTotalStep: string;
}

@Injectable()
export class GtmService {
    instantIssuingCategory = 'Instant issuing';
    private cantPush: boolean;
    private isInLcLan;

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        private router: Router,
        private authService: AuthService,
        private initializer: InitializerService
    ) {
        this.isInLcLan = initializer.getClientContext().IsInMaxLan;
        if (!isPlatformBrowser(platformId) || this.isInLcLan) {
            this.cantPush = true;
            return;
        }

        if (Object.hasOwnProperty.call(window, 'dataLayer') === false) {
            window['dataLayer'] = [];
        }
    }

    pushGeneral(catagory: string, action: string, label: string) {
        this.push(catagory, action, label);
    }
    pushLobby(category: string, action: string, label: string) {
        this.push(`New lobby - ${category}`, action, label);
    }

    /**
     * Push analytics with Category: BDI report
     * @param {string} action
     * @param {string} label
     */
    pushBdi(action: string, label: string): void {
        this.push('BDI report', action, label);
    }

    pushOrderCard(action: string, label: string) {
        this.push('order card', action, label);
    }

    pushFlightCalculator(action: string, label: string) {
        this.push('SKYMAX Calculator', action, label);
    }

    pushMaxBackCalculator(action: string, label: string) {
        this.push('MaxBack Calculator', action, label);
    }

    pushOpenBankingAgreeWorkFlow(action: string, label: string, step: string) {
        this.pushWorkflowGtm('Open Banking - Agree', action, label, '4', 'Open Banking - Agree', step, '4');
    }

    pushOpenBanking(cat: string, action: string, label: string) {
        this.push(cat, action, label);
    }

    pushOpenBankingPermissions(action: string, label: string) {
        this.push('Open Banking permissions', action, label);
    }

    pushInstantIssuing(action: string, label: string) {
        this.push(this.instantIssuingCategory, action, label);
    }

    pushInstantIssuingWorkflow(action: string, label: string, workflowStep: string) {
        this.pushWorkflowGtm(this.instantIssuingCategory, action, label, '4', 'Instant issuing', workflowStep, '10');
    }

    pushInstantIssuingLeumi(action: string, label: string) {
        this.push('Instant issuing Leumi', action, label);
    }

    pushInstantIssuingCardDetails(action: string, label: string) {
        this.push('Card Details', action, label);
    }

    pushExchangeFlightPointsWorkflow(action: string, label: string, step: string) {
        this.pushWorkflowGtm('flight points convert', action, label, '4', 'flight points convert', step, '3');
    }

    pushExchangeFlightPoints(action: string, label: string) {
        this.push('flight points convert', action, label);
    }

    pushInstantIssuingDigitalTransfer(action: string, label: string) {
        this.push('Digital transfer', action, label);
    }

    pushInstantIssuingDigitalTransferWorkflow(action: string, label: string, workflowStep: string) {
        this.pushWorkflowGtm('Digital transfer', action, label, '4', 'Digital transfer', workflowStep, '4');
    }

    pushInstantIssuingDigitalTransferShortProcess(action: string, label: string) {
        this.push('Digital transfer short process', action, label);
    }

    pushInstantIssuingDigitalTransferShortProcessWorkflow(action: string, label: string, workflowStep: string) {
        this.pushWorkflowGtm(
            'Digital transfer short process',
            action,
            label,
            '4',
            'Digital transfer short process',
            workflowStep,
            '2'
        );
    }

    pushBalanceApproval(action: string, label: string) {
        this.push('balance approval report', action, label);
    }

    pushBalanceApprovalWorkFlow(action: string, label: string, step: string) {
        this.pushWorkflowGtm('balance approval report', action, label, '4', 'balance approval report', step, '3');
    }

    pushUserDetails(category:string, action: string, label: string, value?: string) {
        this.push(category, action, label, value);
    }

    pushUserDetailsWorkFlow(category:string, action: string, label: string, wfDesc: string, step: string) {
        this.pushWorkflowGtm(category, action, label, '', wfDesc, step, '4');
    }

    pushRecoverPassword(action: string, label: string) {
        this.push('Recover password', action, label);
    }

    pushRecoverPasswordWorkflow(action: string, label: string, step: string) {
        this.pushWorkflowGtm('Recover password', action, label, '4', 'Recover password', step, '3');
    }

    pushTracker(action: string, label: string) {
        this.push('order card - tracker', action, label);
    }

    pushOrderCardPage(action: string, label: string) {
        this.push('order card - card page', action, label);
    }

    pushP2PPayments(action: string, label: string) {
        this.push('Money transfer', action, label);
    }

    pushFairCredit(action: string, label: string) {
        this.push('Fair credit agreement', action, label);
    }

    pushTransactionDetails(action: string, label: string) {
        this.push('New website - charges', action, label);
    }

    pushTransactionDetailsEthoca(label: string) {
        this.pushTransactionDetails('ethoca', label);
    }

    pushTransactionDetailsUpsaleRow(action: string, label: string) {
        this.push('New website - registered', action, label);
    }
    pushTransactionPersonalMessage(action: string, label: string, value: string) {
        this.push('transaction-details', action, label, value);
    }
    pushAgreement(action: string, label: string) {
        this.push('SSO agreement', action, label);
    }

    pushInsurance(action: string, label: string) {
        this.push('Max insurance', action, label);
    }

    pushLoans(action: string, label: string) {
        this.push('Take a loan', action, label);
    }

    pushExternalLoan(action: string, label: string) {
        this.push('externalLoan', action, label);
    }

    pushLoanGallery(action: string, label: string) {
        this.push('Loan gallery', action, label);
    }

    pushEcomBenefit(action: string, label: string) {
        this.push('Ecom benefit', action, label);
    }

    pushRoladinBenefit(action: string, label: string) {
        this.push('Roladin benefit', action, label);
    }

    pushTransactionDeny(isAbroad: boolean, isFromSso: boolean, action: string, label: string) {
        this.push(
            'CHB ' + (isAbroad ? 'Abroad' : 'Israel') + ' Digital - ' + (isFromSso ? 'app' : 'site'),
            action,
            label
        );
    }

    pushTransactionDenyWorkFlow(isAbroad: boolean, isFromSso: boolean, phase: string, label: string, step: string) {
        this.pushWorkflowGtm(
            'CHB ' + (isAbroad ? 'Abroad' : 'Israel') + ' Digital - ' + (isFromSso ? 'app' : 'site'),
            'Digital CHB - ' + phase,
            label,
            '4',
            'CHB ' + (isAbroad ? 'Abroad' : 'Israel') + ' Digital - ' + (isFromSso ? 'app' : 'site'),
            step,
            isAbroad ? '8' : '7'
        );
    }

    pushAbroadBillingCycle(action: string, label: string) {
        this.push('Change billing type - abroad', action, label);
    }

    pushAbroadBillingCycleWorkFlow(action: string, label: string, step: string) {
        this.pushWorkflowGtm(
            'Change billing type - abroad',
            action,
            label,
            '4',
            'Change billing type - abroad',
            step,
            '3'
        );
    }

    pushSuspiciousTransactions(action: string, label: string) {
        this.push('Suspicious transactions', action, label);
    }

    pushSuspiciousTransactionsWorkFlow(action: string, label: string, step: string) {
        this.pushWorkflowGtm('Suspicious transactions', action, label, '4', 'Suspicious transactions', step, '4');
    }

    pushContactUs(action: string, label: string) {
        this.push('contact us page', action, label);
    }

    pushCustomerLoan(action: string, label: string) {
        this.push('fix charge loan', action, label);
    }

    pushDealTrack(action: string, label: string) {
        this.push('Temporary unapproved transaction', action, label);
    }

    pushCustomerLoanWorkFlow(action: string, label: string, step: string) {
        this.pushWorkflowGtm('fix charge loan', action, label, '4', 'fix charge loan', step, '5');
    }

    pushDapapPopupWorkFlow(action: string, label: string, step: string) {
        this.pushWorkflowGtm('Dapap pop-up', action, label, '4', 'Dapap pop-up', step, '4');
    }

    pushDapapAndLettersList(action: string, label: string) {
        this.push('dapap and letters list', action, label);
    }

    pushLettersTab(action: string, label: string) {
        this.push('Letters_tab', action, label);
    }

    pushDapapTab(action: string, label: string) {
        this.push('dapap_tab', action, label);
    }

    pushDealTrackWorkFlow(action: string, label: string, step: string) {
        this.pushWorkflowGtm(
            'Temporary unapproved transaction',
            action,
            label,
            '4',
            'Temporary unapproved transaction',
            step,
            '4'
        );
    }

    pushFixCharge(category: string, action: string, label: string) {
        this.push('fixed charge – ' + category, action, label);
    }

    pushFixChargeWorkFlow(category: string, action: string, label: string, step: string) {
        const totalSteps = category === 'cancelation' ? '3' : '4';
        this.pushWorkflowGtm(
            'fixed charge – ' + category,
            action,
            label,
            '4',
            'fixed charge – ' + category,
            step,
            totalSteps
        );
    }

    pushFixChargeControlWorkFlow(category: string, action: string, label: string, step: string) {
        this.pushWorkflowGtm(category, action, label, '4', 'fixed charge – ' + category, step, '3');
    }

    pusUnfreezeDirectDebit(action: string, label: string) {
        this.push('Unfreeze direct debit', action, label);
    }

    pushUnfreezeDirectDebitWorkFlow(action: string, label: string, step: string) {
        this.pushWorkflowGtm('Unfreeze direct debit', action, label, '4', 'Unfreeze direct debit', step, '3');
    }

    pushVirtualCreditCardDetails(action: string, label: string) {
        this.push('Virtual credit card details', action, label);
    }

    pushVirtualPrePaidCardDetails(action: string, label: string) {
        this.push('PrePaid card details', action, label);
    }
    pushVirtualPrePaidCardDetailsWf(action: string, label: string, step: string) {
        this.pushWorkflowGtm('PrePaid card details', action, label, '4', 'PrePaid card details', step, '5');
    }

    pushVirtualCreditCardDetailsRecover(action: string, label: string) {
        this.push('Virtual credit card details - Recover code', action, label);
    }

    pushVirtualCreditCardDetailsRecoverWorkFlow(action: string, label: string, step: string) {
        this.pushWorkflowGtm(
            'Virtual credit card details - Recover code',
            action,
            label,
            '4',
            'Virtual credit card details - Recover code',
            step,
            '3'
        );
    }

    pushVirtualCreditCardDetailsWorkFlow(action: string, label: string, step: string) {
        this.pushWorkflowGtm(
            'Virtual credit card details',
            action,
            label,
            '4',
            'Virtual credit card details',
            step,
            '6'
        );
    }

    pushPastMonthlyBillingLayout(action: string, label: string) {
        this.push('Divide past monthly charge', action, label);
    }

    pushPastMonthlyBillingLayoutWorkFlow(action: string, label: string, step: string) {
        this.pushWorkflowGtm('Divide past monthly charge', action, label, '4', 'Divide monthly charge', step, '5');
    }

    pushMonthlyBillingLayout(action: string, label: string) {
        this.push('Divide monthly charge', action, label);
    }

    pushMonthlyBillingLayoutWorkFlow(action: string, label: string, step: string) {
        this.pushWorkflowGtm('Divide monthly charge', action, label, '4', 'Divide monthly charge', step, '5');
    }

    pushMonthlyBillingRegistered(label: string, value: string) {
        this.push('New Website - registered', 'MonthlyBilling', label, value);
    }

    pushDealDetails(action: string, label: string, value: string) {
        this.push('deals details', action, label, value);
    }

    pushMonthlyBillingLayoutJoinUpdateCancel(action: string, label: string) {
        this.push('Divide monthly charge join update, cancel', action, label);
    }

    pushMonthlyBillingLayoutUpdate(action: string, label: string) {
        this.push('Divide monthly charge update', action, label);
    }

    pushMonthlyBillingLayoutUpdateWorkFlow(action: string, label: string, step: string) {
        this.pushWorkflowGtm(
            'Divide monthly charge update',
            action,
            label,
            '4',
            'Divide monthly charge update',
            step,
            '5'
        );
    }

    pushMonthlyBillingLayoutCancel(action: string, label: string) {
        this.push('Divide monthly charge cancel', action, label);
    }

    pushMonthlyBillingLayoutCancelWorkFlow(action: string, label: string, step: string) {
        this.pushWorkflowGtm(
            'Divide monthly charge cancel',
            action,
            label,
            '4',
            'Divide monthly charge cancel',
            step,
            '3'
        );
    }

    pushCancelDenial(action: string, label: string) {
        this.push('Cancel CHB', action, label);
    }

    pushCancelDenialWorkFlow(action: string, label: string, step: string) {
        this.pushWorkflowGtm('Cancel CHB', action, label, '4', 'Cancel CHB', step, '3');
    }

    pushRequestPinCode(action: string, label: string, isAuth = false) {
        let connect = '';
        if (isAuth) {
            connect = 'no otp';
        } else {
            connect = 'anonymous';
        }

        this.push(`recover pin code - ${connect}`, action, label);
    }

    // pushRequestPinCodeWorkFlow(action: string, label: string, step: string, totalStep = '3') {
    //     this.pushWorkflowGtm(
    //         'recover pin code - anonymous',
    //         action,
    //         label,
    //         '4',
    //         'recover pin code - anonymous',
    //         step,
    //         totalStep
    //     );
    // }

    pushRequestPinCodeWorkFlow(
        action: string,
        label: string,
        step: string,
        totalStep = '3',
        isSkipOtp = false,
        isAuth = false
    ) {
        let connect = '';
        if (isSkipOtp && isAuth) {
            connect = 'with otp'; // doesn't need to go to otp screen
        } else if (isAuth) {
            connect = 'no otp'; // need to go to send otp stage
        } else {
            connect = 'anonymous';
        }
        this.pushWorkflowGtm(
            `recover pin code - ${connect}`,
            action,
            label,
            '4',
            `recover pin code - ${connect}`,
            step,
            totalStep
        );
    }

    pushExternalOcrWorkflow(action: string, label: string, workflowStep: string) {
        this.pushWorkflowGtm('External Ocr', action, label, '4', 'External Ocr', workflowStep, '10');
    }

    pushExternalOcr(action: string, label: string) {
        this.push('External Ocr', action, label);
    }

    pushReceivePinCodeWorkflow(
        action: string,
        label: string,
        step: string,
        IsAuth = false,
        IsOtp = false,
        totalStep = '3'
    ) {
        let connect = '';
        if (IsOtp && IsAuth) {
            connect = 'with otp';
        } else if (IsAuth) {
            connect = 'no otp';
        } else {
            connect = 'anonymous';
        }

        const workflowDescription = `present code – ${connect}`;
        const category = `recover pin code - ${connect}`;

        this.pushWorkflowGtm(category, action, label, '4', workflowDescription, step, totalStep);
    }

    pushReceivePinCode(action: string, label: string, IsAuth = false, IsOtp = false) {
        let category = '';
        if (IsOtp && IsAuth) {
            category = 'recover pin code - with otp';
        } else if (IsAuth) {
            category = 'recover pin code - no otp';
        } else {
            category = 'recover pin code - anonymous';
        }

        this.push(category, action, label);
    }

    pushBlockCardWorkflow(action: string, label: string, step: string, typeBlock: BlockingCardStatus) {
        const connect = this.authService.isUserAuthenticated() ? 'registered' : 'anonymous';
        let workflowDescription = `blockcard_`;
        let totalStep = ``;
        switch (typeBlock) {
            case BlockingCardStatus.Swallowed:
                workflowDescription += 'atm';
                totalStep = '4';
                break;
            case BlockingCardStatus.Stolen:
                workflowDescription += 'stolen';
                totalStep = '4';

                break;

            default:
                workflowDescription += 'lost_other';
                totalStep = '6';

                break;
        }
        let category = `blockcard_${connect}`;
        if (typeBlock !== null) {
            switch (typeBlock) {
                case BlockingCardStatus.Swallowed:
                    category += '_atm';
                    break;
                case BlockingCardStatus.Stolen:
                    category += '_stolen';
                    break;
                case BlockingCardStatus.Lost:
                    category += '_lost';
                    break;
                case BlockingCardStatus.Other:
                    category += '_other';
                    break;
                default:
                    break;
            }
        }
        this.pushWorkflowGtm(category, action, label, '4', workflowDescription, step, totalStep);
    }

    pushBlockCard(
        action: string,
        label: string,
        typeBlock: BlockingCardStatus = null,
        InstantIssuing: boolean = false
    ) {
        const connect = this.authService.isUserAuthenticated() ? 'registered' : 'anonymous';

        let category = `blockcard_${connect}`;
        if (InstantIssuing) {
            category = 'Block Card - Instant Issuing';
        }
        if (typeBlock !== null) {
            switch (typeBlock) {
                case BlockingCardStatus.Swallowed:
                    category += '_atm';
                    break;
                case BlockingCardStatus.Stolen:
                    category += '_stolen';
                    break;
                case BlockingCardStatus.Lost:
                    category += '_lost';
                    break;
                case BlockingCardStatus.Other:
                    category += '_other';
                    break;
                default:
                    break;
            }
        }
        this.push(category, action, label);
    }

    pushVirtualGC(action: string, label: string) {
        this.push('Workplace virtual GC', action, label);
    }

    pushVirtualGCWorkFlow(action: string, label: string, step: string) {
        this.pushWorkflowGtm('Workplace virtual GC', action, label, '4', 'Workplace virtual GC', step, '6');
    }

    pushProductPage(action: string, label: string) {
        this.push('New product page – private', action, label);
    }

    pushMagazinePage(action: string, label: string) {
        this.push('Magazine - private', action, label);
    }

    pushDirective(gtm: IGtm) {
        this.push(gtm.category, gtm.action, gtm.label, gtm.value);
    }

    pushWorkflowEvent(gtm: IGtmWorkflow) {
        this.pushWorkflowGtm(
            gtm.category,
            gtm.action,
            gtm.label,
            gtm.subjectId,
            gtm.workflowDescription,
            gtm.workflowStep,
            gtm.workflowTotalStep
        );
    }

    pushMain(category: string, categoryValue: string, action: string, actionValue: string) {
        this.cantPush
            ? console.log('dataLayer not found', category, categoryValue, action, actionValue)
            : window['dataLayer'].push({ [category]: categoryValue, [action]: actionValue });
    }
    pushNewWebsiteAnonymous(action: string, label: string): void {
        this.push('New website - anonymous', action, label);
    }
    pushEarlyRepayment(action: string, label: string) {
        this.push('Divide Monthly Charge Defray', action, label);
    }
    pushEarlyRepaymentWorkflow(action: string, label: string, step: number) {
        this.pushWorkflowGtm(
            'Divide Monthly Charge Defray',
            action,
            label,
            '4',
            'Divide Monthly Charge Defray',
            step.toString(),
            '3'
        );
    }
    pushControl(category: string, action: string, label: string) {
        this.push(category, action, label);
    }
    pushRetroDeploymentApproval(category: string, label: string) {
        this.push(category, 'retro deployment approval', label);
    }
    pushSpreadTransactionCredit(action: string, label: string) {
        this.push('Authorization 3 Payments', action, label);
    }
    pushSpreadTransactionCreditWorkFlow(action: string, label: string, step: string) {
        this.pushWorkflowGtm('Authorization 3 Payments', action, label, '4', 'Transaction 3 Payments', step, '4');
    }
    // tslint:disable-next-line:typedef
    pushSpreadTransactionValue(
        action: string,
        label: string,
        value?: string,
        category: string = 'split transaction - summary'
    ) {
        this.push(category, action, label, value);
    }
    // tslint:disable-next-line:typedef
    pushSpreadTransaction(action: string, label: string, category = 'Retro deployment') {
        this.push(category, action, label);
    }
    pushSpreadTransactionWorkFlow(action: string, label: string, step: string) {
        this.pushWorkflowGtm('Retro deployment', action, label, '4', 'Retro deployment', step, '4');
    }
    // tslint:disable-next-line:typedef
    pushTeachersOrganization(action: string, label: string, category = 'Gift card irgon hamorim') {
        this.push(category, action, label);
    }
    pushGiftCardRefill(category: string, action: string, label: string, value?: string) {
        this.push(category, action, label, value);
    }
    pushTeachersOrganizationWorkFlow(action: string, label: string, step: string) {
        this.pushWorkflowGtm('Gift card irgon hamorim', action, label, '4', 'Gift card irgon hamorim', step, '3');
    }
    pushCreditLimit(action: string, label: string) {
        this.push('credit limit', action, label);
    }
    pushCreditLimitIncreaseWorkFlow(action: string, label: string, step: string) {
        this.pushWorkflowGtm('credit limit', action, label, '4', 'Increase credit limit', step, '2');
    }
    pushCreditTransfert(action: string, label: string) {
        this.push('credit transfer', action, label);
    }
    pushCreditTransfertWorkFlow(action: string, label: string, step: string) {
        this.pushWorkflowGtm('credit transfer', action, label, '4', 'transfer credit limit', step, '3');
    }
    pushSplitTransaction(action: string, label: string) {
        this.push('split transaction - summary', action, label);
    }
    pushThreeDS(action: string, label: string) {
        this.push('Internet transaction biometric confirmation', action, label);
    }
    pushRAFSkymax(action: string, label: string) {
        this.push('Friend Join Status - SKYMAX', action, label);
    }
    pushRAFMaxback(action: string, label: string) {
        this.push('Friend Join Status - maxBack', action, label);
    }
    pushRAFTracker(action: string, label: string) {
        this.push('FriendJoinStatus', action, label);
    }
    startListening(isMobile: boolean) {
        if (isMobile) {
            this.pushMain('CI Reg Website Type', '3', 'CD Reg Website Type', 'Mobile_site');
        }
        // this is disabled by request form lenoid
        // this.router.events.pipe(filter(e => e instanceof RoutesRecognized)).subscribe((e: RoutesRecognized) => {
        //    if (this.router.navigated || e.url !== e.urlAfterRedirects) {
        //        this.pushDirective({
        //            category: window.location.href,
        //            action: 'source',
        //            label:
        //                e.url !== e.urlAfterRedirects
        //                    ? `navigateTo: ${e.url} redirectTo: ${e.urlAfterRedirects}`
        //                    : e.url
        //        });
        //    }
        // });
    }
    pushDirectDebit(action: string, label: string) {
        this.push('Transfer standing order', action, label);
    }
    pushDirectDebitList(action: string, label: string) {
        this.push('DirectDebit', action, label);
    }
    pushDirectDebitWorkFlow(action: string, label: string, step: string) {
        this.pushWorkflowGtm('Transfer standing order', action, label, '4', 'Transfer standing order', step, '3');
    }
    pushNewBenefitPulsePage(action: string, label: string) {
        this.push('New benefit pulse page', action, label);
    }
    pushNewBenefitPulsePageLobby(label: string) {
        this.push('New benefit pulse page', 'Benefits plus lobby', label);
    }
    pushCreditMulti(action: string, label: string) {
        this.push('Multi loan', action, label);
    }
    pushCreditMultiWorkFlow(action: string, label: string, step: string) {
        this.pushWorkflowGtm('Multi loan', action, label, '4', 'Multi loan', step, '5');
    }
    pushDivurYashir(action: string, label: string) {
        this.push('Divur yashir', action, label);
    }

    pushOnBoardAtzmaim(action: string, label: string) {
        this.push('OnBoardAtzmaimNew', action, label);
    }

    pushMaxAccountPaymentRequest(action: string, label: string) {
        this.push('Free profession pay', action, label);
    }

    pushMaxAccountPaymentRequestCustomerSuccess(action: string, label: string) {
        this.push('Free profession pay - customer pay', action, label);
    }

    pushMaxAccountPaymentRequestOnPlaceSuccess(action: string, label: string) {
        this.push('Free profession pay on place', action, label);
    }

    pushPopUpDivur(action: string, label: string, value?: string) {
        // marketing mailing
        this.push('PopUpDivur', action, label, value);
    }

    pushWhatsapp(action: string, label: string) {
        this.push('WhatsApp Agreement', action, label);
    }

    pushUmbracoBenefits(action: string, label: string) {
        this.push('New benefit page', action, label);
    }

    pushTagging(action: string, label: string, categorie: string) {
        this.push(categorie, action, label);
    }
    pushSaving(action: string, label: string, value?: string): void {
        this.push('Investment money', action, label, value);
    }
    pushSavingWorkflow(action: string, label: string, step: string, decription = 'Join saving'): void {
        this.pushWorkflowGtm('Investment money', action, label, '4', decription, step, '5');
    }
    pushExternalSavingWorkflow(action: string, label: string, step: string): void {
        this.pushWorkflowGtm('Investment money', action, label, '4', 'Investment money', step, '7');
    }

    pushDigitalFormSign(action: string, label: string) {
        this.push('Digital Form Sign', action, label);
    }

    pushNewsLetterRegistration(label: string) {
        this.push('Divur shivuki', 'anonymous', label);
    }

    pushJoinBog(action: string, label: string) {
        this.push('Crypto for all  registration', action, label);
    }

    pushJoinEcobill(action: string, label: string) {
        this.push('Join ecobill', action, label);
    }

    pushMaxAccount(category: string, action: string, label: string, value?: string): void {
        if (!!value) {
            this.push(category, action, label, value);
        } else {
            this.push(category, action, label);
        }
    }

    pushPopUp(category: string, action: string, label: string): void {
        this.push(category, action, label);
    }

    pushMarketingSubscribtion(action: string, label: string) {
        this.push('divur_shivuki_registered', action, label);
    }

    pushRegisterWebsite(action: string, label: string): void {
        this.push('Online_registration', action, label);
    }

    pushRegisterWebsiteWorkflow(action: string, label: string, step: string): void {
        this.pushWorkflowGtm('Online_registration', action, label, '4', 'Online_registration', step, '4');
    }

    pushMangementLoyalty(action: string, label: string): void {
        this.push('Benefits plan', action, label);
    }

    // תחילת החלפת מסלול
    pushChangeCourse(action: string, label: string): void {
        this.push('My Benefit plan', action, label);
    }

    pushChangeMainBeneftsPlan(action: string, label: string): void {
        this.push('change main benefits plan', action, label);
    }

    pushJoinBeyahadBishvilchaSky(action: string, label: string): void {
        this.push('Join Beyahad Bishvilcha sky', action, label);
    }
    pushRemovePlan(action: string, label: string): void {
        this.push('Remove plan', action, label);
    }

    // סוף החלפת מסלול

    pushBenefitsHistory(category: string, action: string, label: string): void {
        this.push(category, action, label);
    }

    pushBenefitCacellationWorkflow(action: string, label: string, step: string): void {
        this.pushWorkflowGtm('Cancelling order', action, label, '4', 'Cancelling order', step, '3');
    }

    pushIFrame(action: string, label: string) {
        this.push('i_frame', action, label);
    }

    pushIvrLogin(label: string): void {
        this.push('new website - login page', 'From ivr', label);
    }

    pushReplaceCard(action: string, label: string, reason: ReplaceCardReason = null) {
        const category = 'change defective card';

        if (reason !== null) {
            switch (reason) {
                case ReplaceCardReason.ContactLess:
                    action += ' contactless';
                    break;
                case ReplaceCardReason.NotWorking:
                    action += ' notworking';
                    break;
                case ReplaceCardReason.PealedOrBroken:
                    action += ' pealedorbroken';
                    break;
                default:
                    break;
            }
        }
        this.push(category, action, label);
    }

    pushFCP(category: string, action: string, label: string, value?: string): void {
        this.push(category, action, label, value);
    }

    pushCashBack(action: string, label: string) {
        this.push('cashback', action, label);
    }

    pushTransactionRepayment(action: string, label: string) {
        this.push('Repayment transaction', action, label);
    }

    pushTransactionRepaymentWorkFlow(action: string, label: string, step: string) {
        this.pushWorkflowGtm('Repayment transaction', action, label, '4', 'Repayment transaction', step, '4');
    }

    pushRemoveCircleForGood(action: string, label: string) {
        this.push('igul letova cancellation', action, label);
    }

    pushUpdateFlight(gtm: IGtm) {
        this.push(gtm.category, gtm.action, gtm.label);
    }

    pushUpdateFlightWorkflow(gtm: IGtmWorkflow) {
        this.pushWorkflowGtm(
            gtm.category,
            gtm.action,
            gtm.label,
            gtm.subjectId,
            gtm.workflowDescription,
            gtm.workflowStep,
            gtm.workflowTotalStep
        );
    }

    pushTotalMaxBack(action: string, label: string) {
        this.push('Total maxback', action, label);
    }

    pushTotalMaxBackToGC(action: string, label: string) {
        this.push('Total maxback to GC', action, label);
    }

    pushTotalMaxBackSavingJoinOB(action: string, label: string) {
        this.push('Total maxback to saving join ob', action, label);
    }

    pushTotalMaxBackBOGJoin(action: string, label: string) {
        this.push('Total maxback to crypto open account', action, label);
    }

    pushTotalMaxBackConvert(to: string, action: string, label: string) {
        this.push('Total maxback to ' + to, action, label);
    }

    pushUpgradeToTotalMaxback(action: string, label: string) {
        this.push('Upgrade to total maxback', action, label);
    }

    pushDebtPayment(action: string, label: string) {
        this.push('Payment of debt', action, label);
    }

    pushTransactionsCtrl(action: string, label: string) {
        this.push('transactions ctrl', action, label);
    }

    pushMyCreditProducts(action: string, label: string, value?: string) {
        if (!!value) {
            this.push('My credit products', action, label, value);
        } else {
            this.push('My credit products', action, label);
        }
    }

    pushChangeBillingCycle(action: string, label: string, value?: string) {
        if (!!value) {
            this.push('Change billing cycle', action, label, value);
        } else {
            this.push('Change billing cycle', action, label);
        }
    }

    pushRemoveNewsletter(action: string, label: string) {
        this.push('marketing email', action, label);
    }

    pushChangeBillingCycleWorkflow(action: string, label: string, step: string) {
        this.pushWorkflowGtm('Change billing cycle', action, label, '', 'Change billing cycle', step, '3');
    }

    pushSplitPayments(action: string, label: string) {
        this.push('3payments deals abroad', action, label);
    }

    pushMyBenefits(action: string, label: string, value?: string) {
        if (!!value) {
            this.push('my pinukim', action, label, value);
        } else {
            this.push('my pinukim', action, label);
        }
    }

    pushPublicComplaints(action: string, label: string, value?: string) {
        if (!!value) {
            this.push('public complaints', action, label, value);
        } else {
            this.push('public complaints', action, label);
        }
    }

    pushMyMaxWithdrawal(action: string, label: string): void {
        this.push('my max withdrawal', action, label);
    }
    pushMyMaxOneTimeCharging(action: string, label: string): void {
        this.push('my max one time charging', action, label);
    }
    pushMyMaxOneTimeChargingWorkFlow(action: string, label: string, step: string): void {
        this.pushWorkflowGtm('my max one time charging', action, label, '4', 'My max charging', step, '3');
    }

    pushMyMaxChargingAfterJoining(action: string, label: string): void {
        this.push('my max charging after joining', action, label);
    }

    pushMyMaxChargingAfterJoiningWorkFlow(action: string, label: string, step: string): void {
        this.pushWorkflowGtm(
            'my max charging after joining',
            action,
            label,
            '4',
            'my max charging after joining',
            step,
            '3'
        );
    }

    pushMyMaxMonthlyChargingWorkFlow(action: string, label: string, step: string): void {
        this.pushWorkflowGtm('my max monthly charging', action, label, '4', 'my max monthly charging', step, '3');
    }

    pushMyMaxMonthlyCharging(action: string, label: string): void {
        this.push('my max monthly charging', action, label);
    }
    pushMyMaxMonthlyChargingAfterJoiningWorkFlow(action: string, label: string, step: string): void {
        this.pushWorkflowGtm(
            'my max monthly charging after joining',
            action,
            label,
            '4',
            'my max monthly charging after joining',
            step,
            '3'
        );
    }

    pushMyMaxMonthlyChargingAfterJoining(action: string, label: string): void {
        this.push('my max monthly charging after joining', action, label);
    }
    pushMyMaxErrorPages(category: string, action: string, label: string): void {
        this.push(category, action, label);
    }
    pushMyMax(action: string, label: string): void {
        this.push('my max', action, label);
    }
    pushMyMaxJoining(action: string, label: string): void {
        this.push('my max joining', action, label);
    }

    pushMyMaxJoiningWorkFlow(action: string, label: string, step: string): void {
        this.pushWorkflowGtm('my max joining', action, label, '4', 'my max joining', step, '7');
    }

    pushMyMaxCube(action: string, label: string): void {
        this.push('my max cube', action, label);
    }

    pushTraklinDan(action: string, label: string) {
        this.push('benefit traklindan', action, label);
    }

    pushOrderBenefit(action: string, label: string) {
        this.push('order benefit', action, label);
    }

    pushOrderCoupon(action: string, label: string) {
        this.push('order coupon', action, label);
    }

    pushOrderBenefitCoupon(action: string, label: string) {
        this.push('order benefit / order coupon', action, label);
    }

    pushOrderBenefitWorkflow(action: string, label: string, step: string) {
        this.pushWorkflowGtm('order benefit', action, label, '4', 'Order benefit', step, '4');
    }

    pushOrderCouponWorkflow(action: string, label: string, step: string) {
        this.pushWorkflowGtm('order coupon', action, label, '4', 'Order benefit', step, '4');
    }

    pushGCActivation(category: string, action: string, label: string, value?: string) {
        if (!!value) {
            this.push(category, action, label, value);
        } else {
            this.push(category, action, label);
        }
    }

    pushMyMaxCardholder(action: string, label: string) {
        this.push('my max card holder', action, label);
    }

    pushMyMaxCardholderFirstLogin(action: string, label: string) {
        this.push('my max card holder first login', action, label);
    }

    pushStrictIdentificationCheck(label: string) {
        this.push('StrictIdentification', 'check', label);
    }
    pushGCTransactions(category: string, action: string, label: string) {
        this.push(category, action, label);
    }

    pushDapapRule434(action: string, label: string){
        this.push('dapap rule_434', action, label);
    }
    pushMailAndDapap(action: string, label: string) {
        this.push('dgtl dapap and letters', action, label);
    }

    push(category: string, action: string, label: string, value?: string) {
        this.cantPush
            ? console.log({ category, action, label, value })
            : this.pushToDataLayer({ category, action, label, value });
    }
    // tslint:disable-next-line: member-ordering
    pushCancelDirectDebitWorkflow(action: string, label: string, step: string) {
        this.pushWorkflowGtm('cancel direct debit', action, label, '', 'cancel direct debit', step, '3');
    }

    // tslint:disable-next-line: member-ordering
    pushCancelDirectDebit(action: string, label: string, value?: string) {
        if (!!value) {
            this.push('cancel direct debit', action, label, value);
        } else {
            this.push('cancel direct debit', action, label);
        }
    }

    pushWorkflowGtm(
        category: string,
        action: string,
        label: string,
        subjectId: string,
        workflowDescription: string,
        workflowStep: string,
        workflowTotalStep: string
    ) {
        this.cantPush
            ? console.log({ category, action, label, subjectId, workflowDescription, workflowStep, workflowTotalStep })
            : this.pushToDataLayerWorkflow({
                  category,
                  action,
                  label,
                  subjectId,
                  workflowDescription,
                  workflowStep,
                  workflowTotalStep
              });
    }

    private pushToDataLayer(gtm: IGtm) {
        window['dataLayer'].push({
            Category: gtm.category,
            Action: gtm.action,
            Label: gtm.label,
            SubjectId: undefined,
            WorkflowDescription: undefined,
            WorkflowStep: undefined,
            WorkflowTotalStep: undefined,
            event: 'auto_event',
            Value: gtm.value
        });
    }

    private pushToDataLayerWorkflow(gtm: IGtmWorkflow) {
        window['dataLayer'].push({
            Category: gtm.category,
            Action: gtm.action,
            Label: gtm.label,
            SubjectId: gtm.subjectId,
            WorkflowDescription: gtm.workflowDescription,
            WorkflowStep: gtm.workflowStep,
            WorkflowTotalStep: gtm.workflowTotalStep,
            event: 'auto_event'
        });
    }
}
